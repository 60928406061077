@import '../../../../../styles/variables.scss';
.story_embed_text_area {

  &__title {
    margin-top: map-get($spacer, 'xl');
  }

  &__description {
    margin-top: map-get($spacer, 'md');
    color: $color-neutral-600;
  }

  textarea {
    width: 100%;
    margin-top: map-get($spacer,'xl');
    padding: map-get($spacer,'lg');
    border: none;
    border-radius: 10px;
    background: $color-neutral-200;
    font-family: 'Courier New', Courier, monospace;
    overflow-y: scroll;
    resize: none;
}

}
