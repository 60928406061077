@import '../variables.scss';

@mixin outline-styles(
  $focusBorderWidth: 2px,
  $focusBorderRadius: null,
  $focusBorderGutter: -4px,
  $focusPosition: null,
  $borderColor: $text-900
) {
  position: $focusPosition;
  &:focus {
    &:after,
    &-visible:after {
      content: '';
      position: absolute;
      border: $focusBorderWidth solid $borderColor;
      left: $focusBorderGutter;
      right: $focusBorderGutter;
      top: $focusBorderGutter;
      bottom: $focusBorderGutter;
      border-radius: $focusBorderRadius;
    }
    &:not(:focus-visible):after {
      content: none;
    }
    outline: none;
  }
}
