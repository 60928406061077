@import '../../../../styles/variables';

.web_preview {
  &__wrapper {
    padding: map-get($spacer, xs) map-get($spacer, xs) 0 map-get($spacer, md);
    background-color: $color-neutral-100;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
  }
  &__browser {
    &_icons {
      display: flex;
      gap: map-get($spacer, xs);
      margin-bottom: map-get($spacer, xs);
    }
    &_icon {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      display: inline-block;
      background-color: $color-neutral-200;
    }
  }
  &__tab {
    width: 260px;
    display: flex;
    align-items: center;
    gap: map-get($spacer, xs);
    margin-left: map-get($spacer, md);
    background-color: $color-bg-100;
    box-shadow: 0px -2px 5px rgba($color-neutral-700, 0.06);
    border-radius: 20px 20px 0px 0px;
    padding: map-get($spacer, xs) map-get($spacer, sm) map-get($spacer, xs)
      map-get($spacer, sm);
    &_logo {
      width: 30px;
      height: 30px;
      border-radius: 5px;
    }
    &_name {
      height: 9px;
      flex-grow: 1;
      border-radius: 3px;
      background-color: $color-neutral-100;
    }
    &_icon {
      display: flex;
      align-items: center;
    }
  }
}
