@import '../../../styles/variables.scss';

.taxonomy {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;

  h2 {
    margin-bottom: 10px;
  }

  &__section {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    gap: 4px;
    padding: 2px 0;

    &__container {
      display: flex;
      flex-wrap: wrap;

      &__tag_container {
        width: fit-content;
        height: 28px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
        margin: 2px 4px 2px 0;
        padding: 6px 12px;
        border-radius: 3px;
        background-color: #e7e6e9;

        &--empty {
          color: $grey;
        }

        &--tagLabel {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    &--tagType {
      width: 91px;
      height: 20px;
    }
  }
}
