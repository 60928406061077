@import '../../../styles/variables.scss';
@import '../../../styles/mixins/link_styles.scss';

.gallery_list {
  &.emptyState {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }
  &--actions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 24px;
    .input-component {
      margin: 0;
      &__label-container {
        display: none;
      }
      .cr__icon-search {
        position: absolute;
        top: 15px;
        left: 14px;
        z-index: $z-index-middle;
      }
      input {
        width: 354px;
        height: 44px;
        margin: 0;
        padding: 12px 30px 12px 36px;
        border-radius: 100px;
        &:focus {
          outline: none;
        }
        &[type='search']::-webkit-search-cancel-button {
          // This removes the clear button that is only visible in chrome
          /* stylelint-disable property-no-vendor-prefix */
          -webkit-appearance: none;
        }
      }
    }
  }
  &--create_gallery_button {
    @include linkButton();
  }
}
