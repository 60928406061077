@import "../../../styles/variables.scss";
@import "../../../styles/mixins/general";

$active-font-weight: 700;

.tabs_bar {
  &__underline {
    border-bottom: 1px solid $color-neutral-200;
  }

  .tabs_bar__indicator {
    height: 3px;
    background-color: $color-primary-400;
  }

  .MuiTabs-root {
    min-height: 24px;
  }

  .MuiTabs-flexContainer {
    gap: map-get($spacer, "xl");
  }

  .MuiTab-root {
    min-height: 24px;
    min-width: 48px;
    padding: 5px 1px;
    font-family: $base-font;
    font-style: normal;
    font-size: map-get($font-size, 'md');
    font-weight: 400;
    text-transform: unset;
    line-height: map-get($line-height, 'md');
    color: $color-neutral-500;
    opacity: 1;

    &:hover {
      font-weight: 700;
    }

    // Hidden pseudo element to prevent option container
    // from growing on hover
    .tabs_bar__tab_wrapper::after {
      display: block;
      height: 0;
      margin-top: -1px;
      font-weight: $active-font-weight;
      content: attr(data-text);
      overflow: hidden;
      visibility: hidden;
      user-select: none;
      pointer-events: none;
    }

    @include outline-styles(
      $focusBorderWidth: 1px,
      $focusBorderRadius: 5px,
      $focusBorderGutter: 0px,
    );
  }

  .Mui-selected {
    color: unset;
    font-weight: 700;
  }
}
