@import "../../../styles/variables.scss";

.gallery-sortable {
  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    flex-wrap: wrap;
    gap: $space-lg;
    margin-top: $space-5xlg;

    &:hover {
      > li {
        will-change: transform;
      }
    }
    > li {
      position: relative;
    }
  }
}
