@import '../../../styles/mixins/story_dimensions.scss';

.story_moderation_modal__content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    min-height: 270px;

    &--left {
        flex: 61% 0 1;
        & ul {
            list-style: disc;
            line-height: 20px;
            padding-left: 20px;
        
            & li {
                line-height: 20px;
                margin: 8px 0;
            }
          }
    }

    &__story_preview {
        flex: 35% 0 0;
        max-height: 400px;
        .story {
            @include storyDimensions(286px, 100%);
        }
    }
}
