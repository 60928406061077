@import '../../../../styles/variables';
@import '../../../../styles/mixins/link_styles';

.name-gallery-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .name-gallery-section__content {
    h2 {
      font-size: map-get($font-size, 'xxl');
      font-weight: 700;
    }

    &--description {
      margin-top: 18px;
      color: $color-neutral-600;
    }
  }

  &__input {
    width: 100%;

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
      color: $color-neutral-600;
      top: 0;
    }

    .MuiFormLabel-root {
      font-size: map-get($font-size, 'md');
    }

    .MuiFormHelperText-root {
      margin-top: 0;
    }

    .MuiInputBase-input {
      font-size: map-get($font-size, 'md');
      padding-bottom: 14px;
      color: black;
    }

    .MuiInput-underline:after {
      border-color: $color-neutral-700;
    }

    .name-gallery-section__input-helper-text {
      text-align: right;
      display: flex;
      justify-content: space-between;

      .name-gallery-section__error-message,
      .name-gallery-section__char-count {
        font-size: 11px;
        margin-top: 9px;
      }

      .name-gallery-section__error-message {
        color: #f44336;
      }

      .name-gallery-section__char-count {
        color: $color-neutral-500;
      }
    }
  }

  .name-gallery-section__form {
    margin: 25px 0 0 0;
  }

  &__next-step-button {
    display: block;
    margin-left: auto;
    margin-top: 40px;
  }
}
