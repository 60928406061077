@import '../variables.scss';
@mixin formFooter() {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  border-top: 1px solid $grey;
  button {
    margin: 33px 0 10px 0;
    display: flex;
    position: relative;
    a {
      color: $white;
      :first-child {
        margin: 0 0 0 3px;
      }
      .iconWrapper.cr__icon-external-link {
        padding: 0;
      }
    }
  }
}
