@import '../../../styles/variables.scss';

.Toastify {
  .toastContainer {
    width: auto;
    display: flex;
    flex-direction: column;
    z-index: 1200;

    .Toastify__toast {
      font-family: $base-font;
      padding: map-get($spacer, 'xs') map-get($spacer, 'md');
      display: flex;
      align-items: center;
      gap: map-get($spacer, 'md');
      border-radius: 10px;
      align-self: flex-end;

      &-body {
        padding: 0;
        margin: 0;

        &__container {
          display: flex;
          column-gap: map-get($spacer, 'md');
          align-items: center;

          &__banner-img {
            width: 60px;
            border-radius: map-get($spacer, 'xs');
          }

          &__message-wrapper {
            display: flex;
            flex-direction: column;

            &__message {
              max-width: 250px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: $color-neutral-600;
            }

            &__description {
              color: $color-neutral-700;
              margin-top: 2px;
            }

            &__status {
              margin-top: map-get($spacer, 'xs');
              color: $color-neutral-600;
              display: flex;
              align-items: center;
              gap: map-get($spacer, 'xxs');
            }
          }
        }
      }
    }
  }
}
