@import '../../../styles/variables.scss';

$RADIO-SIZE: 14px;

.radio-group {
  &:focus {
    outline: none;
  }

  &.section {
    margin: map-get($spacer, 'xl') 0 0 0;
  }

  &__label-container {
    margin: 0 0 map-get($spacer, 'xs');
    h2 {
      margin: 0;
    }
  }

  &__radio-container {
    display: flex;
    flex-direction: column;
    gap: map-get($spacer, 'xxs');

    &__hidden-input {
      opacity: 0;
      display: none;
    }

    &__list-item {
      padding: 0 !important ;
    }

    &__hidden-input.checked + &__label:before {
      background: radial-gradient($color-neutral-700 4px, $color-bg-100 3px);
    }

    &__hidden-input.disabled + &__label {
      &:before {
        cursor: not-allowed;
        border-color: $color-neutral-500;
      }
    }

    &__hidden-input.disabled.checked + &__label:before {
      background: radial-gradient($color-neutral-500 4px, $color-bg-100 3px);
    }

    &__hidden-input.disabled:hover + &__label:before {
      box-shadow: none;
    }

    &__hidden-input.focused + &__label:before {
      box-shadow: 0 0 0px map-get($spacer, "xxs") $color-primary-100;
    }

    &__label {
      position: relative;

      display: block;
      font-size: map-get($font-size, 'md');
      line-height: map-get($line-height, 'md');
      color: $color-neutral-700;

      max-width: fit-content;

      cursor: pointer;

      &:before {
        position: absolute;
        top: 12%;
        content: '';
        display: inline-block;
        border-radius: 50%;
        width: $RADIO-SIZE;
        height: $RADIO-SIZE;
        border: 1px solid $color-neutral-700;
        background-color: $color-bg-100;
      }

      &:hover,
      &:focus {
        &:before {
          box-shadow: 0 0 0px 5px $color-primary-50;
        }
      }

      span {
        display: inline-block;
        padding-left: map-get($spacer, 'lg');
      }
    }
  }
}
