@import '../../../styles/variables.scss';
.gallery_edit_page__body {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 36px;

  .gallery_form,
  .gallery_edit_page__body__col-right {
    flex-basis: calc(50% - 25px);
    padding-bottom: 150px;
  }
}
.gallery_edit_page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
