.gallery_list--empty_state {
    /* image for this page needs to have this width. Doing this
        instead of changing container size because this is the
        only page that requires an image this size, and due to
        the other elements remaining with the same width */
    .empty_state__graphic {
        max-width: none;
        width: 540px;
    }
}
