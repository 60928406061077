@import '../../../styles/variables.scss';
@import '../../../styles/mixins/legacy_buttons.scss';

.menu {
  z-index: $z-index-middle;
  right: 0;
  background: $color-bg-100;
  border: 1px solid $color-neutral-100;
  border-radius: 10px;
  box-shadow: 0 2px 16px rgba($color-neutral-700, 0.15);
  overflow: hidden;

  &.menu--fixed {
    position: absolute;
  }

  .menu-item {
    padding: 0;
    &:first-child .menu-item {
      &__btn,
      &__link {
        &:focus {
          &:after,
          &-visible:after {
            border-radius: 10px 10px 5px 5px;
          }
        }
      }
    }
    &:last-child .menu-item {
      &__btn,
      &__link {
        &:focus {
          &:after,
          &-visible:after {
            border-radius: 5px 5px 10px 10px;
          }
        }
      }
    }

    &__btn,
    &__link {
      @include legacy-button(
        $borderRadius: 0,
        $hoverBgColor: $color-primary-50,
        $focusBorderWidth: 1px,
        $focusBorderGutter: 0px,
        $focusBorderRadius: 5px,
        $padding: map-get($spacer, 'xs') map-get($spacer, 'sm'),
        $fontSize: map-get($font-size, 'sm'),
        $lineHeight: map-get($line-height, 'sm')
      );
      width: max-content;
      max-width: 300px;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 5px;
      flex: 100% 1 0;
    }

    &__description {
      flex: 100% 1 0;
      font-size: map-get($font-size, 'xs');
      line-height: map-get($line-height, 'xs');
      color: $color-neutral-600;
      width: max-content;
      text-align: left;
    }
  }

  &_searchable {
    width: 180px;
    &__input {
      &_wrapper {
        display: flex;
        align-items: center;
        gap: map-get($spacer, 'xs');
        padding: 12px map-get($spacer, 'sm');
        position: relative;
      }
      &_box {
        font-size: map-get($font-size, 'sm');
        line-height: map-get($line-height, 'sm');
        background: transparent;
        border: none;
        color: $color-neutral-600;

        &::placeholder {
          color: $color-neutral-600;
        }
        &:focus {
          &::placeholder {
            color: $color-neutral-300;
          }
          + .menu_searchable__input_divider {
            border-bottom: 1px solid $color-neutral-700;
          }
        }
      }
      &_divider {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        margin: 0;
        border-width: 0;
        border-bottom: 1px solid $color-neutral-200;
      }
    }
  }
}
