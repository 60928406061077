@import '../../../styles/variables.scss';
.toggle_button__container {
  display: inline-block;
  input[type='checkbox'] {
    height: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    &:focus + .toggle_button {
      outline: 1px -webkit-focus-ring-color solid;
    }
  }

  .toggle_button {
    display: flex;
    align-items: baseline;
    padding: 6px 12px;
    border: 1px solid $text-900;
    border-radius: 50px;
    color: $blue-black;
    &:hover {
      cursor: pointer;
    }
    .toggle_button__icon {
      padding-right: 6px;
    }
    span {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      text-transform: capitalize;
    }
  }
  input:checked + .toggle_button {
    background: $text-900;
    color: $text-100;
  }
}
