@import '../../../styles/variables.scss';
@import '../../../styles/mixins/avatar_size.scss';
.publisher-label {
    display: inline-flex;
    align-items: center;
    height: 26px;
    padding: 1px 10px 1px 1px;
    border-radius: 100px;

    .avatar-component {
        margin-right: map-get($spacer, 'xs');
        img {
            border: none;
        }
        &.small {
            @include avatarSize(26px, 0);
        }
    }

    p {
        margin: 0;
        font-weight: 500;
        font-size: map-get($font-size, 'sm');
        color: $color-neutral-700;
        line-height: map-get($line-height, 'sm');
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
