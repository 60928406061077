@import '../../../styles/variables.scss';

.stepper {
  display: flex;
  align-items: center;
  cursor: default;

  .stepper__root--default {
    padding: 0;
    height: 100%;

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;

      text {
        font-family: $base-font;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 20px;
        font-weight: 700;
      }
    }
  }

  .MuiStepLabel-iconContainer {
    padding-right: 0;

    .MuiStepIcon-active.MuiStepIcon-root {
      color: rgba(50, 50, 53, 1);
      outline: none;

      text {
        fill: #f2f2f3;
      }
    }

    .MuiStepIcon-root {
      color: #f2f2f3;
      outline: 1px solid #e4e4e6;
      border-radius: 50%;

      text {
        fill: #323235;
      }
    }
  }

  .MuiStep-horizontal {
    padding: 0;
  }

  .MuiStepConnector-line {
    border-color: $color-neutral-300;
  }

  .MuiStepConnector-lineHorizontal {
    width: 10px;
  }
}
