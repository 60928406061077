@import '../../../styles/variables.scss';

.cr-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;

  &__wrapper {
    &_box {
      position: relative;
    }
    &_square {
      height: 14px;
      width: 14px;
    }
    &_circle {
      height: 30px;
      width: 30px;
    }
  }
  &__input {
    appearance: none;
    cursor: pointer;
    height: 100%;
    width: 100%;
    outline: none;
    outline-offset: 0;
    &_square {
      border: 1px solid $color-neutral-700;
      border-radius: 4px;
      &:hover:not(:disabled) {
        outline: 5px solid $color-primary-50;
      }
      &.invalid {
        border: 1px solid $color-error-700;
      }
      &:disabled {
        border: 1px solid $color-neutral-500;
        cursor: not-allowed;
        &:checked {
          background-color: $color-neutral-500;
        }
      }
      &:checked {
        background-color: $color-neutral-700;
      }
      &:focus,
      &:focus-visible {
        outline: 5px solid $color-primary-100;
      }
    }
    &_circle {
      border-radius: 50%;
      background-color: rgba($color-bg-100, 0.4);
      border: 1px solid $color-bg-100;
      &.alternate + .cr-checkbox__icon--plus--circle {
        opacity: 1;
      }
      &:checked {
        border: 0;
        background-color: rgba($color-bg-100, 0.4);
      }
      &:hover {
        &:not(:checked) {
          &.alternate {
            border: 0;
            background-color: transparent;
          }
          & + .cr-checkbox__icon--plus--circle {
            fill: $color-neutral-700;
            background-color: $color-bg-100;
          }
        }
      }
      &:focus-visible {
        &:not(:checked) {
          border: 0;
          background-color: transparent;
          & + .cr-checkbox__icon--plus--circle {
            opacity: 1;
            fill: $color-neutral-700;
            background-color: $color-bg-100;
            border: 2px solid $color-neutral-700;
          }
        }
      }
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    margin: auto;
    width: fit-content;
    height: fit-content;
    fill: $color-bg-100;
    &--checkmark--circle {
      background-color: $color-neutral-700;
      fill: $color-bg-100;
      border-radius: 50%;
      padding: map-get($spacer, 'xxs');
    }
    &--plus--circle {
      border-radius: 50%;
      padding: 3px;
      opacity: 0;
      fill: $color-neutral-600;
    }
  }

  &__label-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: map-get($spacer, 'sm');

    &.invalid,
    &.required {
      padding-top: map-get($spacer, 'sm');
    }
  }
  &__label {
    font-size: map-get($font-size, 'md');
    line-height: map-get($line-height, 'md');
    cursor: pointer;
    align-self: flex-start;
  }

  &__error-message {
    color: $color-error-700;
  }
}
