@import '../../../../styles/variables.scss';

.qr_code_modal {
  &__container {
    display: flex;
    gap: map-get($spacer, 'md');
  }

  &__description {
    margin-bottom: map-get($spacer, 'md');;
  }
  &__content-wrapper {
    justify-content: center;
    align-items: center;
    gap: 32px;
    display: flex;
    width: fit-content;
    margin: 0 auto;

    &__content-text-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: map-get($spacer, 'sm');
      display: inline-flex;
    }

    &__content-text {
      justify-content: flex-start;
      align-items: center;
      gap: map-get($spacer, 'xxs');
      display: inline-flex;
    }
  }
}
