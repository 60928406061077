@import '../../../styles/variables.scss';
@import '../../../styles/mixins/general.scss';
@import '../../../styles/mixins/story_dimensions.scss';
$lines-to-show: 3;
$card-border-radius: 15px;
$outline-radius: $card-border-radius + 3;
.story {
  @include storyDimensions(220px, 385px);
  position: relative;
  border-radius: $card-border-radius;

  &__visual_link {
    @include outline-styles(
      $focusBorderWidth: 2px,
      $focusBorderRadius: $outline-radius
    );
  }

  &--content {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: $card-border-radius;
  }
  &--header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &--status_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 81px;
    height: 48px;
    gap: map-get($spacer, 'xs');
    margin: 0 0 209px 59.4px;
    padding: 0;
  }

  &--overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: space-between;
    padding: $space-md;
    border-radius: $card-border-radius;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }

  &__description_text {
    max-width: 220px;
    max-height: map-get($line-height, 'lg') * $lines-to-show;
    margin: 0;
    color: $white;
    font-weight: 700;
    font-size: map-get($font-size, 'lg');
    line-height: map-get($line-height, 'lg');
    -webkit-line-clamp: $lines-to-show;
    line-clamp: $lines-to-show;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--checkbox {
    background: blue;
    height: 100px;
    width: 100px;
  }

  &.story--empty_daft {
    color: $disabled-grey;
    .story--overlay {
      background: $light-grey;
    }
    .iconWrapper {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .story--description {
      p {
        color: $blue-black;
      }
    }
  }
  &.story--hidden {
    .story--overlay {
      z-index: $z-index-bottom;
    }
  }
}
