@import './variables.scss';

html {
    box-sizing: border-box;
    min-width: $page-min-width;
    overflow: scroll;
}

body {
    margin: 0;
}

// stylelint-disable
*, 
*:before,
*:after {
    box-sizing: inherit;
}
// stylelint-enable

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'] {
    width: 100%;

    &:focus {
        outline: none;
    }
}

ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
}
