.detailed_story {
  &--menu {
    &_wrapper {
      position: relative;

      .menu {
        bottom: 100%;
      }
    }
  }
}
