@import '../../../styles/variables.scss';
.gallery_create_page__body {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-top: 36px;
    padding-bottom: 150px;

    .gallery_form,
    .gallery_preview {
        flex-basis: calc(50% - 25px);
    }
}