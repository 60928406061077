@import '../../../../styles/variables.scss';


.story_stats {
  &__tooltip {
    display: flex;
    flex-direction: column;
    gap: map-get($spacer, 'xs');
  }

  &__label {
    display: flex;
    justify-content: space-between;
  }
}
