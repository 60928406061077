@import '../../../../styles/variables.scss';

.story_export_modal {
  &__container {
    display: flex;
    gap: map-get($spacer, 'md');
  }
  &__poster-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 15px;
    overflow: hidden;
    height: 268px;
    width: 150px;
    background: $light-grey; // TODO: Need to alter with a color from palette, along with story list item background color
  }
  &__poster-text {
    position: absolute;
    bottom: 0;
    padding: map-get($spacer, 'sm');
    color: $text-100;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
  &__poster {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__checkbox-wrapper {
    margin: map-get($spacer, 'xs') map-get($spacer, 'xxs');
    display: 'flex';
    flex-direction: 'column';
    gap: map-get($spacer, 'md');
  }
  &__sub-include-checkbox {
    margin: map-get($spacer, 'xs') 0 0 map-get($spacer, 'md');
  }
  &__content-text {
    width: 351;
  }
  &__individuals {
    margin-top: map-get($spacer, 'xs');

    &-alert {
      padding-left: map-get($spacer, 'lg');
    }
  }
  .photo-empty {
    color: $disabled-grey; // TODO: Need to alter with a color from palette, along with story list item background color
  }
  &__radio-wrapper {
    margin-bottom: map-get($spacer, 'sm');
  }
}
