.cr-alertpanel {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid #007BA8;
  border-radius: 3px;
  background-color: #EBF6F9;
  box-sizing: border-box;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 21px; }
  .cr-alertpanel__title {
    color: #2a2f35;
    font-weight: 700; }
    .cr-alertpanel__title--icon {
      margin-top: 4px;
      margin-right: 8px;
      color: #007BA8; }
  .cr-alertpanel__description {
    color: #666;
    font-style: normal;
    font-weight: normal; }
  .cr-alertpanel__link {
    color: #007BA8;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; }
    .cr-alertpanel__link--link {
      display: flex; }
    .cr-alertpanel__link--icon {
      margin-top: 4px;
      margin-left: 4px; }
  .cr-alertpanel__textWithLink {
    color: #666;
    font-size: 14px; }
    .cr-alertpanel__textWithLink__link {
      margin-left: 4px;
      color: #007BA8;
      font-weight: bold;
      line-height: 18px;
      text-decoration-line: underline;
      cursor: pointer; }
      .cr-alertpanel__textWithLink__link .cr-alertpanel__textWithLink__link__icon {
        display: inline;
        margin-left: 4px; }
  .cr-alertpanel--fail {
    border: 1px solid #ffccc7;
    background-color: #fff2f0; }
    .cr-alertpanel--fail .cr-alertpanel__title {
      color: #2a2f35;
      font-weight: 700; }
      .cr-alertpanel--fail .cr-alertpanel__title--icon {
        color: #e21039; }

[id^='chart-'] {
  padding: 24px; }

.cr-chart {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; }

.recharts-text {
  fill-opacity: 0.24; }

.cr-chart:hover .recharts-text {
  fill-opacity: 1; }

.cr-chart .recharts-xAxis .recharts-cartesian-axis-tick:first-child {
  transform: translateX(4px); }

.cr-chart .recharts-xAxis .recharts-cartesian-axis-tick:last-child {
  transform: translateX(-4px); }

.cr-chart .recharts-yAxis .recharts-cartesian-axis-tick:first-child {
  display: none; }

.cr-chart .recharts-yAxis .recharts-cartesian-axis-tick:last-child {
  transform: translateY(12px); }

.cr-chart .recharts-yAxis .recharts-cartesian-axis-ticks {
  transform: translateX(-12px); }

.asset {
  display: inline-block;
  position: relative; }
  .asset__playButtonContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%); }
  .asset__img {
    display: block;
    position: relative;
    max-width: 100%; }
  .asset__adjuster {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center; }
  .asset__adjusterBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black; }

.cr__avatar {
  display: flex;
  align-items: center; }
  .cr__avatar__wrapper {
    position: relative;
    padding: 3px;
    border: 2px solid transparent;
    border-radius: 50%; }
    .cr__avatar__wrapper.selected {
      border: 2px solid #007BA8; }
    .cr__avatar__wrapper:focus {
      outline: none; }
  .cr__avatar__wrapper.selectable {
    cursor: pointer; }
    .cr__avatar__wrapper.selectable:hover {
      border: 2px solid #007BA8; }
  .cr__avatar__image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%; }
  .cr__avatar__fallback-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #666;
    color: #ffffff;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase; }
    .cr__avatar__fallback-initials.twitter {
      background: #2CA9E1; }
    .cr__avatar__fallback-initials.facebook {
      background: #3B5999; }
    .cr__avatar__fallback-initials.instagram {
      background: #e53c5f; }
  .cr__avatar__network {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 4px;
    transform: translate(33%, 33%);
    border-radius: 50%;
    background: #ffffff; }
  .cr__avatar__displayName {
    margin-left: 8px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold; }
  .cr__avatar__complete {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    margin: 3px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.56); }
  .cr__avatar__byline {
    margin-left: 8px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 14px; }

.button {
  display: block;
  position: relative;
  padding: 7px 15px;
  transition: 0.1s all ease-in;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  cursor: pointer;
  opacity: 1; }
  .button .iconWrapper {
    padding-right: 8px;
    padding-left: 0;
    float: left; }
  .button.right-icon .iconWrapper {
    padding-right: 0;
    padding-left: 8px;
    float: right; }
  .button.centered {
    margin: 0 auto; }
  .button.circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 16px; }
  .button.white {
    border: 1px solid white;
    background-color: white;
    color: #010202; }
    .button.white:hover, .button.white:active, .button.white:focus {
      border-color: rgba(255, 255, 255, 0.9);
      background-color: rgba(255, 255, 255, 0.9); }
    .button.white.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: white; }
      .button.white.secondary:hover, .button.white.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: rgba(255, 255, 255, 0.9); }
        .button.white.secondary:hover .icon, .button.white.secondary:active .icon {
          color: rgba(255, 255, 255, 0.9); }
        .button.white.secondary:hover .cr__icon, .button.white.secondary:active .cr__icon {
          color: rgba(255, 255, 255, 0.9); }
  .button.blue {
    border: 1px solid #007BA8;
    background-color: #007BA8; }
    .button.blue:hover, .button.blue:active, .button.blue:focus {
      border-color: #00455E;
      background-color: #00455E; }
    .button.blue.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #007BA8; }
      .button.blue.secondary:hover, .button.blue.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #00455E; }
        .button.blue.secondary:hover .icon, .button.blue.secondary:active .icon {
          color: #00455E; }
        .button.blue.secondary:hover .cr__icon, .button.blue.secondary:active .cr__icon {
          color: #00455E; }
  .button.bright-blue {
    border: 1px solid #19b6f3;
    background-color: #19b6f3; }
    .button.bright-blue:hover, .button.bright-blue:active, .button.bright-blue:focus {
      border-color: #0d9bd2;
      background-color: #0d9bd2; }
    .button.bright-blue.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #19b6f3; }
      .button.bright-blue.secondary:hover, .button.bright-blue.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #0d9bd2; }
        .button.bright-blue.secondary:hover .icon, .button.bright-blue.secondary:active .icon {
          color: #0d9bd2; }
        .button.bright-blue.secondary:hover .cr__icon, .button.bright-blue.secondary:active .cr__icon {
          color: #0d9bd2; }
  .button.red {
    border: 1px solid #e21039;
    background-color: #e21039; }
    .button.red:hover, .button.red:active, .button.red:focus {
      border-color: #b40f31;
      background-color: #b40f31; }
    .button.red.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #e21039; }
      .button.red.secondary:hover, .button.red.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #b40f31; }
        .button.red.secondary:hover .icon, .button.red.secondary:active .icon {
          color: #b40f31; }
        .button.red.secondary:hover .cr__icon, .button.red.secondary:active .cr__icon {
          color: #b40f31; }
  .button.purple {
    border: 1px solid #a455ff;
    background-color: #a455ff; }
    .button.purple:hover, .button.purple:active, .button.purple:focus {
      border-color: #712bc2;
      background-color: #712bc2; }
    .button.purple.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #a455ff; }
      .button.purple.secondary:hover, .button.purple.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #712bc2; }
        .button.purple.secondary:hover .icon, .button.purple.secondary:active .icon {
          color: #712bc2; }
        .button.purple.secondary:hover .cr__icon, .button.purple.secondary:active .cr__icon {
          color: #712bc2; }
  .button.grey {
    border: 1px solid #666;
    background-color: #666; }
    .button.grey:hover, .button.grey:active, .button.grey:focus {
      border-color: #007BA8;
      background-color: #007BA8; }
    .button.grey.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #666; }
      .button.grey.secondary:hover, .button.grey.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #007BA8; }
        .button.grey.secondary:hover .icon, .button.grey.secondary:active .icon {
          color: #007BA8; }
        .button.grey.secondary:hover .cr__icon, .button.grey.secondary:active .cr__icon {
          color: #007BA8; }
  .button.pink {
    border: 1px solid #eb5a7b;
    background-color: #eb5a7b; }
    .button.pink:hover, .button.pink:active, .button.pink:focus {
      border-color: #ca4261;
      background-color: #ca4261; }
    .button.pink.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #eb5a7b; }
      .button.pink.secondary:hover, .button.pink.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #ca4261; }
        .button.pink.secondary:hover .icon, .button.pink.secondary:active .icon {
          color: #ca4261; }
        .button.pink.secondary:hover .cr__icon, .button.pink.secondary:active .cr__icon {
          color: #ca4261; }
  .button.green {
    border: 1px solid #8bbb39;
    background-color: #8bbb39; }
    .button.green:hover, .button.green:active, .button.green:focus {
      border-color: #729932;
      background-color: #729932; }
    .button.green.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #8bbb39; }
      .button.green.secondary:hover, .button.green.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #729932; }
        .button.green.secondary:hover .icon, .button.green.secondary:active .icon {
          color: #729932; }
        .button.green.secondary:hover .cr__icon, .button.green.secondary:active .cr__icon {
          color: #729932; }
  .button.success-green {
    border: 1px solid #1eac71;
    background-color: #1eac71; }
    .button.success-green:hover, .button.success-green:active, .button.success-green:focus {
      border-color: #1b835a;
      background-color: #1b835a; }
    .button.success-green.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #1eac71; }
      .button.success-green.secondary:hover, .button.success-green.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #1b835a; }
        .button.success-green.secondary:hover .icon, .button.success-green.secondary:active .icon {
          color: #1b835a; }
        .button.success-green.secondary:hover .cr__icon, .button.success-green.secondary:active .cr__icon {
          color: #1b835a; }
  .button.twitter {
    border: 1px solid #2CA9E1;
    background-color: #2CA9E1; }
    .button.twitter:hover, .button.twitter:active, .button.twitter:focus {
      border-color: #1b8cbf;
      background-color: #1b8cbf; }
    .button.twitter.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #2CA9E1; }
      .button.twitter.secondary:hover, .button.twitter.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #1b8cbf; }
        .button.twitter.secondary:hover .icon, .button.twitter.secondary:active .icon {
          color: #1b8cbf; }
        .button.twitter.secondary:hover .cr__icon, .button.twitter.secondary:active .cr__icon {
          color: #1b8cbf; }
  .button.facebook {
    border: 1px solid #3B5999;
    background-color: #3B5999; }
    .button.facebook:hover, .button.facebook:active, .button.facebook:focus {
      border-color: #2d4474;
      background-color: #2d4474; }
    .button.facebook.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #3B5999; }
      .button.facebook.secondary:hover, .button.facebook.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #2d4474; }
        .button.facebook.secondary:hover .icon, .button.facebook.secondary:active .icon {
          color: #2d4474; }
        .button.facebook.secondary:hover .cr__icon, .button.facebook.secondary:active .cr__icon {
          color: #2d4474; }
  .button.instagram {
    border: 1px solid #e53c5f;
    background-color: #e53c5f; }
    .button.instagram:hover, .button.instagram:active, .button.instagram:focus {
      border-color: #d21c42;
      background-color: #d21c42; }
    .button.instagram.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #e53c5f; }
      .button.instagram.secondary:hover, .button.instagram.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #d21c42; }
        .button.instagram.secondary:hover .icon, .button.instagram.secondary:active .icon {
          color: #d21c42; }
        .button.instagram.secondary:hover .cr__icon, .button.instagram.secondary:active .cr__icon {
          color: #d21c42; }
  .button.appear-disabled {
    border: 1px solid #c5c8ca;
    background-color: #c5c8ca; }
    .button.appear-disabled:hover, .button.appear-disabled:active, .button.appear-disabled:focus {
      border-color: #c5c8ca;
      background-color: #c5c8ca; }
    .button.appear-disabled.secondary {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      color: #c5c8ca; }
      .button.appear-disabled.secondary:hover, .button.appear-disabled.secondary:active {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        color: #c5c8ca; }
        .button.appear-disabled.secondary:hover .icon, .button.appear-disabled.secondary:active .icon {
          color: #c5c8ca; }
        .button.appear-disabled.secondary:hover .cr__icon, .button.appear-disabled.secondary:active .cr__icon {
          color: #c5c8ca; }
  .button.fade-out {
    opacity: 0; }
  .button.secondary {
    padding: 8px 0;
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 300;
    text-align: left; }
    .button.secondary.disabled {
      border: none;
      color: #c5c8ca; }
  .button.input {
    position: absolute;
    right: 0;
    border-radius: 0 3px 3px 0; }
  .button.beside-input {
    margin-bottom: 8px;
    padding-left: 16px; }
    .button.beside-input.red {
      color: #666; }
      .button.beside-input.red:hover {
        color: #e21039; }
        .button.beside-input.red:hover .input {
          color: #e21039; }
  .button.disabled {
    border: 1px solid #c5c8ca;
    background-color: rgba(0, 0, 0, 0);
    color: #c5c8ca;
    pointer-events: none; }
    .button.disabled.filled {
      border: 1px solid transparent;
      background-color: #666;
      color: #f5f7f9; }
      .button.disabled.filled i {
        color: #f5f7f9; }
      .button.disabled.filled .cr__icon {
        fill: #f5f7f9; }
    .button.disabled:focus, .button.disabled:active, .button.disabled:hover {
      border: 1px solid #c5c8ca;
      background-color: rgba(0, 0, 0, 0);
      color: #c5c8ca; }
    .button.disabled .icon {
      color: #c5c8ca; }
    .button.disabled .cr__icon {
      fill: #c5c8ca; }
  .button .spinnerLoader {
    position: relative;
    top: 2px; }

.cr__button {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 16px;
  transition: 0.1s all ease-in;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  opacity: 1; }
  .cr__button.full-width {
    justify-content: center;
    width: 100%;
    padding: 15px; }
  .cr__button.blue {
    border: 1px solid #007BA8;
    background-color: #007BA8; }
    .cr__button.blue:hover, .cr__button.blue:active, .cr__button.blue:focus {
      border-color: #00455E;
      background-color: #00455E; }
    .cr__button.blue.disabled {
      border-color: #007BA8;
      background-color: #007BA8; }
    .cr__button.blue.outline {
      border: 1px solid #007BA8;
      background-color: #ffffff;
      color: #007BA8; }
      .cr__button.blue.outline:hover, .cr__button.blue.outline:active {
        border: 1px solid #00455E;
        background-color: #ffffff;
        color: #00455E; }
        .cr__button.blue.outline:hover .icon,
        .cr__button.blue.outline:hover .cr__icon, .cr__button.blue.outline:active .icon,
        .cr__button.blue.outline:active .cr__icon {
          color: #00455E; }
      .cr__button.blue.outline.disabled {
        border: 1px solid #00455E;
        color: #007BA8; }
        .cr__button.blue.outline.disabled .icon,
        .cr__button.blue.outline.disabled .cr__icon {
          color: #007BA8; }
    .cr__button.blue.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #007BA8; }
      .cr__button.blue.link:hover, .cr__button.blue.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #00455E; }
        .cr__button.blue.link:hover .icon,
        .cr__button.blue.link:hover .cr__icon, .cr__button.blue.link:active .icon,
        .cr__button.blue.link:active .cr__icon {
          color: #00455E; }
      .cr__button.blue.link.disabled {
        color: #007BA8; }
        .cr__button.blue.link.disabled .icon,
        .cr__button.blue.link.disabled .cr__icon {
          color: #007BA8; }
  .cr__button.lightBlue {
    border: 1px solid #10b5f5;
    background-color: #10b5f5; }
    .cr__button.lightBlue:hover, .cr__button.lightBlue:active, .cr__button.lightBlue:focus {
      border-color: #10b5f5;
      background-color: #10b5f5; }
    .cr__button.lightBlue.disabled {
      border-color: #10b5f5;
      background-color: #10b5f5; }
    .cr__button.lightBlue.outline {
      border: 1px solid #10b5f5;
      background-color: #ffffff;
      color: #10b5f5; }
      .cr__button.lightBlue.outline:hover, .cr__button.lightBlue.outline:active {
        border: 1px solid #10b5f5;
        background-color: #ffffff;
        color: #10b5f5; }
        .cr__button.lightBlue.outline:hover .icon,
        .cr__button.lightBlue.outline:hover .cr__icon, .cr__button.lightBlue.outline:active .icon,
        .cr__button.lightBlue.outline:active .cr__icon {
          color: #10b5f5; }
      .cr__button.lightBlue.outline.disabled {
        border: 1px solid #10b5f5;
        color: #10b5f5; }
        .cr__button.lightBlue.outline.disabled .icon,
        .cr__button.lightBlue.outline.disabled .cr__icon {
          color: #10b5f5; }
    .cr__button.lightBlue.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #10b5f5; }
      .cr__button.lightBlue.link:hover, .cr__button.lightBlue.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #10b5f5; }
        .cr__button.lightBlue.link:hover .icon,
        .cr__button.lightBlue.link:hover .cr__icon, .cr__button.lightBlue.link:active .icon,
        .cr__button.lightBlue.link:active .cr__icon {
          color: #10b5f5; }
      .cr__button.lightBlue.link.disabled {
        color: #10b5f5; }
        .cr__button.lightBlue.link.disabled .icon,
        .cr__button.lightBlue.link.disabled .cr__icon {
          color: #10b5f5; }
  .cr__button.grey {
    border: 1px solid #666;
    background-color: #666; }
    .cr__button.grey:hover, .cr__button.grey:active, .cr__button.grey:focus {
      border-color: #007BA8;
      background-color: #007BA8; }
    .cr__button.grey.disabled {
      border-color: #666;
      background-color: #666; }
    .cr__button.grey.outline {
      border: 1px solid #666;
      background-color: #ffffff;
      color: #666; }
      .cr__button.grey.outline:hover, .cr__button.grey.outline:active {
        border: 1px solid #007BA8;
        background-color: #ffffff;
        color: #007BA8; }
        .cr__button.grey.outline:hover .icon,
        .cr__button.grey.outline:hover .cr__icon, .cr__button.grey.outline:active .icon,
        .cr__button.grey.outline:active .cr__icon {
          color: #007BA8; }
      .cr__button.grey.outline.disabled {
        border: 1px solid #007BA8;
        color: #666; }
        .cr__button.grey.outline.disabled .icon,
        .cr__button.grey.outline.disabled .cr__icon {
          color: #666; }
    .cr__button.grey.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #666; }
      .cr__button.grey.link:hover, .cr__button.grey.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #007BA8; }
        .cr__button.grey.link:hover .icon,
        .cr__button.grey.link:hover .cr__icon, .cr__button.grey.link:active .icon,
        .cr__button.grey.link:active .cr__icon {
          color: #007BA8; }
      .cr__button.grey.link.disabled {
        color: #666; }
        .cr__button.grey.link.disabled .icon,
        .cr__button.grey.link.disabled .cr__icon {
          color: #666; }
  .cr__button.green {
    border: 1px solid #8bbb39;
    background-color: #8bbb39; }
    .cr__button.green:hover, .cr__button.green:active, .cr__button.green:focus {
      border-color: #729932;
      background-color: #729932; }
    .cr__button.green.disabled {
      border-color: #8bbb39;
      background-color: #8bbb39; }
    .cr__button.green.outline {
      border: 1px solid #8bbb39;
      background-color: #ffffff;
      color: #8bbb39; }
      .cr__button.green.outline:hover, .cr__button.green.outline:active {
        border: 1px solid #729932;
        background-color: #ffffff;
        color: #729932; }
        .cr__button.green.outline:hover .icon,
        .cr__button.green.outline:hover .cr__icon, .cr__button.green.outline:active .icon,
        .cr__button.green.outline:active .cr__icon {
          color: #729932; }
      .cr__button.green.outline.disabled {
        border: 1px solid #729932;
        color: #8bbb39; }
        .cr__button.green.outline.disabled .icon,
        .cr__button.green.outline.disabled .cr__icon {
          color: #8bbb39; }
    .cr__button.green.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #8bbb39; }
      .cr__button.green.link:hover, .cr__button.green.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #729932; }
        .cr__button.green.link:hover .icon,
        .cr__button.green.link:hover .cr__icon, .cr__button.green.link:active .icon,
        .cr__button.green.link:active .cr__icon {
          color: #729932; }
      .cr__button.green.link.disabled {
        color: #8bbb39; }
        .cr__button.green.link.disabled .icon,
        .cr__button.green.link.disabled .cr__icon {
          color: #8bbb39; }
  .cr__button.red {
    border: 1px solid #e21039;
    background-color: #e21039; }
    .cr__button.red:hover, .cr__button.red:active, .cr__button.red:focus {
      border-color: #b40f31;
      background-color: #b40f31; }
    .cr__button.red.disabled {
      border-color: #e21039;
      background-color: #e21039; }
    .cr__button.red.outline {
      border: 1px solid #e21039;
      background-color: #ffffff;
      color: #e21039; }
      .cr__button.red.outline:hover, .cr__button.red.outline:active {
        border: 1px solid #b40f31;
        background-color: #ffffff;
        color: #b40f31; }
        .cr__button.red.outline:hover .icon,
        .cr__button.red.outline:hover .cr__icon, .cr__button.red.outline:active .icon,
        .cr__button.red.outline:active .cr__icon {
          color: #b40f31; }
      .cr__button.red.outline.disabled {
        border: 1px solid #b40f31;
        color: #e21039; }
        .cr__button.red.outline.disabled .icon,
        .cr__button.red.outline.disabled .cr__icon {
          color: #e21039; }
    .cr__button.red.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #e21039; }
      .cr__button.red.link:hover, .cr__button.red.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #b40f31; }
        .cr__button.red.link:hover .icon,
        .cr__button.red.link:hover .cr__icon, .cr__button.red.link:active .icon,
        .cr__button.red.link:active .cr__icon {
          color: #b40f31; }
      .cr__button.red.link.disabled {
        color: #e21039; }
        .cr__button.red.link.disabled .icon,
        .cr__button.red.link.disabled .cr__icon {
          color: #e21039; }
  .cr__button.negative {
    border: 1px solid #666;
    background-color: #666; }
    .cr__button.negative:hover, .cr__button.negative:active, .cr__button.negative:focus {
      border-color: #e21039;
      background-color: #e21039; }
    .cr__button.negative.disabled {
      border-color: #666;
      background-color: #666; }
    .cr__button.negative.outline {
      border: 1px solid #666;
      background-color: #ffffff;
      color: #666; }
      .cr__button.negative.outline:hover, .cr__button.negative.outline:active {
        border: 1px solid #e21039;
        background-color: #ffffff;
        color: #e21039; }
        .cr__button.negative.outline:hover .icon,
        .cr__button.negative.outline:hover .cr__icon, .cr__button.negative.outline:active .icon,
        .cr__button.negative.outline:active .cr__icon {
          color: #e21039; }
      .cr__button.negative.outline.disabled {
        border: 1px solid #e21039;
        color: #666; }
        .cr__button.negative.outline.disabled .icon,
        .cr__button.negative.outline.disabled .cr__icon {
          color: #666; }
    .cr__button.negative.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #666; }
      .cr__button.negative.link:hover, .cr__button.negative.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #e21039; }
        .cr__button.negative.link:hover .icon,
        .cr__button.negative.link:hover .cr__icon, .cr__button.negative.link:active .icon,
        .cr__button.negative.link:active .cr__icon {
          color: #e21039; }
      .cr__button.negative.link.disabled {
        color: #666; }
        .cr__button.negative.link.disabled .icon,
        .cr__button.negative.link.disabled .cr__icon {
          color: #666; }
  .cr__button.twitter {
    border: 1px solid #2CA9E1;
    background-color: #2CA9E1; }
    .cr__button.twitter:hover, .cr__button.twitter:active, .cr__button.twitter:focus {
      border-color: #1b8cbf;
      background-color: #1b8cbf; }
    .cr__button.twitter.disabled {
      border-color: #2CA9E1;
      background-color: #2CA9E1; }
    .cr__button.twitter.outline {
      border: 1px solid #2CA9E1;
      background-color: #ffffff;
      color: #2CA9E1; }
      .cr__button.twitter.outline:hover, .cr__button.twitter.outline:active {
        border: 1px solid #1b8cbf;
        background-color: #ffffff;
        color: #1b8cbf; }
        .cr__button.twitter.outline:hover .icon,
        .cr__button.twitter.outline:hover .cr__icon, .cr__button.twitter.outline:active .icon,
        .cr__button.twitter.outline:active .cr__icon {
          color: #1b8cbf; }
      .cr__button.twitter.outline.disabled {
        border: 1px solid #1b8cbf;
        color: #2CA9E1; }
        .cr__button.twitter.outline.disabled .icon,
        .cr__button.twitter.outline.disabled .cr__icon {
          color: #2CA9E1; }
    .cr__button.twitter.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #2CA9E1; }
      .cr__button.twitter.link:hover, .cr__button.twitter.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #1b8cbf; }
        .cr__button.twitter.link:hover .icon,
        .cr__button.twitter.link:hover .cr__icon, .cr__button.twitter.link:active .icon,
        .cr__button.twitter.link:active .cr__icon {
          color: #1b8cbf; }
      .cr__button.twitter.link.disabled {
        color: #2CA9E1; }
        .cr__button.twitter.link.disabled .icon,
        .cr__button.twitter.link.disabled .cr__icon {
          color: #2CA9E1; }
  .cr__button.facebook {
    border: 1px solid #3B5999;
    background-color: #3B5999; }
    .cr__button.facebook:hover, .cr__button.facebook:active, .cr__button.facebook:focus {
      border-color: #2d4474;
      background-color: #2d4474; }
    .cr__button.facebook.disabled {
      border-color: #3B5999;
      background-color: #3B5999; }
    .cr__button.facebook.outline {
      border: 1px solid #3B5999;
      background-color: #ffffff;
      color: #3B5999; }
      .cr__button.facebook.outline:hover, .cr__button.facebook.outline:active {
        border: 1px solid #2d4474;
        background-color: #ffffff;
        color: #2d4474; }
        .cr__button.facebook.outline:hover .icon,
        .cr__button.facebook.outline:hover .cr__icon, .cr__button.facebook.outline:active .icon,
        .cr__button.facebook.outline:active .cr__icon {
          color: #2d4474; }
      .cr__button.facebook.outline.disabled {
        border: 1px solid #2d4474;
        color: #3B5999; }
        .cr__button.facebook.outline.disabled .icon,
        .cr__button.facebook.outline.disabled .cr__icon {
          color: #3B5999; }
    .cr__button.facebook.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #3B5999; }
      .cr__button.facebook.link:hover, .cr__button.facebook.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #2d4474; }
        .cr__button.facebook.link:hover .icon,
        .cr__button.facebook.link:hover .cr__icon, .cr__button.facebook.link:active .icon,
        .cr__button.facebook.link:active .cr__icon {
          color: #2d4474; }
      .cr__button.facebook.link.disabled {
        color: #3B5999; }
        .cr__button.facebook.link.disabled .icon,
        .cr__button.facebook.link.disabled .cr__icon {
          color: #3B5999; }
  .cr__button.instagram {
    border: 1px solid #e53c5f;
    background-color: #e53c5f; }
    .cr__button.instagram:hover, .cr__button.instagram:active, .cr__button.instagram:focus {
      border-color: #d21c42;
      background-color: #d21c42; }
    .cr__button.instagram.disabled {
      border-color: #e53c5f;
      background-color: #e53c5f; }
    .cr__button.instagram.outline {
      border: 1px solid #e53c5f;
      background-color: #ffffff;
      color: #e53c5f; }
      .cr__button.instagram.outline:hover, .cr__button.instagram.outline:active {
        border: 1px solid #d21c42;
        background-color: #ffffff;
        color: #d21c42; }
        .cr__button.instagram.outline:hover .icon,
        .cr__button.instagram.outline:hover .cr__icon, .cr__button.instagram.outline:active .icon,
        .cr__button.instagram.outline:active .cr__icon {
          color: #d21c42; }
      .cr__button.instagram.outline.disabled {
        border: 1px solid #d21c42;
        color: #e53c5f; }
        .cr__button.instagram.outline.disabled .icon,
        .cr__button.instagram.outline.disabled .cr__icon {
          color: #e53c5f; }
    .cr__button.instagram.link {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      color: #e53c5f; }
      .cr__button.instagram.link:hover, .cr__button.instagram.link:active {
        border: 1px solid transparent;
        background-color: rgba(0, 0, 0, 0);
        color: #d21c42; }
        .cr__button.instagram.link:hover .icon,
        .cr__button.instagram.link:hover .cr__icon, .cr__button.instagram.link:active .icon,
        .cr__button.instagram.link:active .cr__icon {
          color: #d21c42; }
      .cr__button.instagram.link.disabled {
        color: #e53c5f; }
        .cr__button.instagram.link.disabled .icon,
        .cr__button.instagram.link.disabled .cr__icon {
          color: #e53c5f; }
  .cr__button.neutral-50 {
    border: 1px solid #F1F4F8;
    background-color: #F1F4F8;
    color: #2A2B30; }
    .cr__button.neutral-50:hover {
      border-color: #e0e4eb;
      background-color: #e0e4eb; }
    .cr__button.neutral-50:focus-visible {
      transition: none;
      border-radius: 4px;
      outline: 2px solid #004972;
      outline-offset: 2px; }
    .cr__button.neutral-50.outline {
      border-color: #A0A5AF;
      background-color: transparent; }
      .cr__button.neutral-50.outline:hover {
        border-color: #4C4F56;
        background-color: #F1F4F8; }
    .cr__button.neutral-50.link {
      border-color: transparent;
      background-color: transparent; }
  .cr__button.link {
    padding: 0;
    border-radius: 0; }
  .cr__button.disabled {
    cursor: not-allowed;
    opacity: 0.7; }
  .cr__button__children {
    display: flex;
    justify-content: space-between; }
  .cr__button.icon-left .iconWrapper {
    padding-right: 8px; }
  .cr__button.icon-right .iconWrapper {
    padding-left: 8px; }
  .cr__button.square {
    padding: 7px; }
    .cr__button.square .iconWrapper {
      padding: 0; }
  .cr__button.disableVerticalAlign {
    align-items: unset; }

.cr__card {
  flex: 1;
  margin: 24px 0;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16); }
  .cr__card__title {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1; }
  .cr__card__description {
    font-size: 16px; }
  .cr__card > :nth-child(n + 2) {
    margin: 12px 0 0 0; }
  .cr__card__metrics {
    display: flex;
    justify-content: space-between; }

.cr__cards__row {
  display: flex;
  margin-bottom: 24px; }
  .cr__cards__row > :nth-child(n) {
    margin: 0; }
  .cr__cards__row > :nth-child(n + 2) {
    margin-left: 24px; }

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .checkbox__label {
    display: flex;
    position: relative;
    align-items: center;
    padding-bottom: 0;
    color: #2a2f35;
    font-weight: 300;
    user-select: none;
    cursor: pointer; }
  .checkbox__box {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    transition: 0.1s all ease-in;
    border: 1px solid #666;
    border-radius: 4px;
    background: #ffffff;
    color: #ffffff;
    font-size: 8px;
    cursor: pointer; }
    .checkbox__box.checked {
      border-color: #007BA8;
      background-color: #007BA8; }
      .checkbox__box.checked:hover {
        border-color: #00455E;
        background-color: #00455E; }
    .checkbox__box:hover {
      border-color: #00455E; }
  .checkbox__icon .iconWrapper {
    display: flex;
    align-items: center; }
  .checkbox input[type='checkbox'] {
    display: none; }
  .checkbox.disabled .checkbox__label {
    color: #c5c8ca;
    cursor: default;
    pointer-events: none; }
  .checkbox.disabled .checkbox__box {
    border: 1px solid #c5c8ca;
    color: #c5c8ca; }

.cr-contextMenu {
  display: inline-block;
  position: relative; }
  .cr-contextMenu__listWrap {
    position: absolute;
    z-index: 1;
    min-width: 100px;
    margin-top: 8px;
    border: 1px solid #dfe0e1;
    border-radius: 4px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    overflow: hidden; }
  .cr-contextMenu__listItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    color: #2a2f35;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer; }
    .cr-contextMenu__listItem:hover {
      border-radius: 4px;
      background: #f5f7f9; }
  .cr-contextMenu__divider {
    position: relative;
    left: 5%;
    width: 90%;
    margin: 4px 0;
    border-bottom: 1px solid #dfe0e1; }
  .cr-contextMenu .cr__button {
    display: flex;
    align-items: center;
    justify-content: center; }
  .cr-contextMenu.alignLeft > .cr-contextMenu__listWrap {
    left: 0; }
  .cr-contextMenu.alignRight > .cr-contextMenu__listWrap {
    right: 0; }

.dynamicTabs {
  display: flex;
  width: 100%; }
  .dynamicTabs.underline {
    border-bottom: 1px solid #dfe0e1; }
  .dynamicTabs__headerTabs {
    display: flex;
    gap: 50px; }
  .dynamicTabs__itemWrapper {
    display: inline-block;
    padding-bottom: 5px;
    color: #72757E;
    font-weight: 400; }
    .dynamicTabs__itemWrapper:hover {
      color: #2A2B30;
      font-weight: 700; }
    .dynamicTabs__itemWrapper--isActive {
      color: #2A2B30;
      font-weight: 700; }
      .dynamicTabs__itemWrapper--isActive.blue {
        border-bottom: 3px solid #07a0d5; }
      .dynamicTabs__itemWrapper--isActive.green {
        border-bottom: 3px solid #8bbb39; }
      .dynamicTabs__itemWrapper--isActive.red {
        border-bottom: 3px solid #e21039; }
      .dynamicTabs__itemWrapper--isActive.grey {
        border-bottom: 3px solid #666; }
      .dynamicTabs__itemWrapper--isActive.instagram {
        border-bottom: 3px solid #e53c5f; }
      .dynamicTabs__itemWrapper--isActive.twitter {
        border-bottom: 3px solid #2CA9E1; }
      .dynamicTabs__itemWrapper--isActive.facebook {
        border-bottom: 3px solid #3B5999; }
    .dynamicTabs__itemWrapper::after {
      display: block;
      height: 0;
      margin-top: -1px;
      font-weight: 700;
      content: attr(data-text);
      overflow: hidden;
      visibility: hidden;
      user-select: none;
      pointer-events: none; }
  .dynamicTabs__button {
    padding: 0;
    cursor: pointer; }
    .dynamicTabs__button:focus {
      outline: none;
      font-weight: bold; }

.cr-tooltip .cr-tooltip__tooltip {
  position: relative;
  max-width: 232px;
  padding: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #010202;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25); }

.cr-tooltip__content {
  padding: 16px; }

.cr-tooltip__close {
  position: absolute;
  top: 8px;
  right: 8px; }

.cr-tooltipV2 {
  /* CSS to add a little caret/triangle to the tooltip.
       Depending on the placement specified, it'll need to be positioned accordingly.
       The class is added dynamically depending on the `placement` prop passed to the component
       Currently, the caret only accounts for `top` and `bottom` placement atm.
    */ }
  .cr-tooltipV2__caret--top:after {
    position: absolute;
    top: 100%;
    left: calc(50% - 8px);
    border-top: 8px solid #2a2f35;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: ''; }
  .cr-tooltipV2__caret--bottom:after {
    position: absolute;
    top: -8px;
    left: calc(50% - 8px);
    border-right: 8px solid transparent;
    border-bottom: 8px solid #2a2f35;
    border-left: 8px solid transparent;
    content: ''; }
  .cr-tooltipV2__caret--left:after {
    position: absolute;
    top: calc(50% - 8px);
    right: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #2a2f35;
    content: ''; }
  .cr-tooltipV2__caret--right:after {
    position: absolute;
    top: calc(50% - 8px);
    left: -8px;
    border-top: 8px solid transparent;
    border-right: 8px solid #2a2f35;
    border-bottom: 8px solid transparent;
    content: ''; }
  .cr-tooltipV2--light .cr-tooltipV2__caret--top:after {
    border-top-color: #ffffff; }
  .cr-tooltipV2--light .cr-tooltipV2__caret--bottom:after {
    border-bottom-color: #ffffff; }
  .cr-tooltipV2--light .cr-tooltipV2__caret--left:after {
    border-left-color: #ffffff; }
  .cr-tooltipV2--light .cr-tooltipV2__caret--right:after {
    border-right-color: #ffffff; }
  .cr-tooltipV2 .cr-tooltipV2__tooltip {
    position: relative;
    left: -4px;
    align-items: center;
    justify-content: center;
    max-width: 296px;
    height: auto;
    padding: 16px;
    border-radius: 4px;
    background-color: #2a2f35;
    color: #010202;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25); }
    .cr-tooltipV2 .cr-tooltipV2__tooltip--hasClose {
      padding-right: 28px; }
  .cr-tooltipV2__close {
    position: absolute;
    top: 8px;
    right: 8px; }
  .cr-tooltipV2__title {
    margin-bottom: 4px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px; }
  .cr-tooltipV2__description {
    margin-bottom: 12px;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px; }
  .cr-tooltipV2__link {
    display: flex;
    align-items: center;
    margin-top: 12px;
    color: #007BA8;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.01em;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer; }
    .cr-tooltipV2__link div {
      margin-left: 3px; }
  .cr-tooltipV2__list {
    color: #ffffff;
    font-size: 14px; }
    .cr-tooltipV2__list__title {
      margin-bottom: 4px;
      font-weight: 700; }
    .cr-tooltipV2__list__items {
      padding-left: 16px;
      list-style: disc; }
      .cr-tooltipV2__list__items li {
        margin: 2px 0; }
  .cr-tooltipV2__textWithLink {
    color: #ffffff;
    font-size: 14px; }
    .cr-tooltipV2__textWithLink__link {
      margin-left: 4px;
      color: #007BA8;
      font-weight: bold;
      letter-spacing: 0.01em;
      line-height: 18px;
      text-decoration-line: underline;
      cursor: pointer; }
      .cr-tooltipV2__textWithLink__link .cr-tooltipV2__textWithLink__link__icon {
        display: inline;
        margin-left: 4px; }
  .cr-tooltipV2--light .cr-tooltipV2__tooltip {
    background-color: #ffffff; }
  .cr-tooltipV2--light .cr-tooltipV2__title, .cr-tooltipV2--light .cr-tooltipV2__description, .cr-tooltipV2--light .cr-tooltipV2__list, .cr-tooltipV2--light .cr-tooltipV2__textWithLink {
    color: #2a2f35; }

.cr-expansionPanel {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; }
  .cr-expansionPanel__label {
    display: flex;
    margin-left: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; }
  .cr-expansionPanel__content {
    display: flex;
    font-size: 16px;
    line-height: 24px; }
    .cr-expansionPanel__content ul {
      list-style-type: disc;
      list-style-position: inside;
      margin-left: 10px; }

.filterDropdown .Select-value-label {
  font-weight: 300; }

.filterDropdown .Select-noresults {
  font-weight: 300; }

.filterDropdown__label {
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding-bottom: 6px;
  color: #2a2f35;
  font-weight: 300;
  line-height: 1;
  user-select: none;
  cursor: default; }

.filterDropdown .required {
  height: 11px;
  color: #e21039;
  font-weight: 700; }

.filterDropdown.inline {
  display: flex;
  align-items: center; }
  .filterDropdown.inline .Select-control,
  .filterDropdown.inline .Select-value,
  .filterDropdown.inline .Select-option.is-selected,
  .filterDropdown.inline .Select-option.is-focused {
    background-color: transparent; }
  .filterDropdown.inline .filterDropdown__label {
    padding-bottom: 0;
    font-weight: bold;
    line-height: 17px; }

.filterDropdown__groupedVersion {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; }
  .filterDropdown__groupedVersion__optionContainer {
    display: flex; }
    .filterDropdown__groupedVersion__optionContainer:hover {
      background-color: #F4F7FD; }
    .filterDropdown__groupedVersion__optionContainer--selected {
      align-items: center;
      justify-content: space-between; }
      .filterDropdown__groupedVersion__optionContainer--selected:hover {
        background: none; }
      .filterDropdown__groupedVersion__optionContainer--selected .checkIcon {
        margin-right: 15px; }
    .filterDropdown__groupedVersion__optionContainer--isDisabled {
      pointer-events: none; }
  .filterDropdown__groupedVersion__group:last-child {
    padding-bottom: 0; }
  .filterDropdown__groupedVersion__group:not(:last-child) {
    position: relative; }
    .filterDropdown__groupedVersion__group:not(:last-child)::after {
      display: block;
      margin: 0 12px;
      padding-top: 20px;
      border-bottom: 1px solid #dfe0e1;
      content: ''; }
  .filterDropdown__groupedVersion__option {
    margin-right: 5px;
    padding: 14px 24px !important;
    color: #2a2f35;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    word-break: break-word; }
    .filterDropdown__groupedVersion__option:hover {
      background-color: #F4F7FD; }
    .filterDropdown__groupedVersion__option--is-focused, .filterDropdown__groupedVersion__option--is-selected {
      background: none !important; }

.filterDropdownV2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .filterDropdownV2--isInline {
    flex-direction: row;
    align-items: center; }
    .filterDropdownV2--isInline .filterDropdown__label {
      padding-right: 8px;
      white-space: nowrap; }
  .filterDropdownV2__labelContainer {
    padding-bottom: 4px;
    color: #4C4F56;
    font-size: 14px;
    line-height: 20px; }
  .filterDropdownV2__labelRow {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .filterDropdownV2__label {
    padding-right: 8px;
    color: #2A2B30;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap; }
  .filterDropdownV2__count {
    color: #4C4F56;
    font-weight: 700; }
  .filterDropdownV2__required {
    padding-left: 8px; }
  .filterDropdownV2__errorText {
    padding-top: 4px;
    color: #e20328; }
  .filterDropdownV2__checkIcon {
    padding-left: 16px;
    color: #72757E; }
  .filterDropdownV2__warningIcon {
    position: absolute;
    right: 0;
    color: #e20328; }

.heroHeader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.iconWrapper {
  display: flex; }
  .iconWrapper .cr__icon {
    transition: fill 0.3s ease; }
  .iconWrapper.twitter, .iconWrapper.network-twitter {
    color: #2CA9E1; }
  .iconWrapper.instagram, .iconWrapper.network-instagram {
    color: #e53c5f; }
  .iconWrapper.facebook, .iconWrapper.network-facebook {
    color: #3B5999; }
  .iconWrapper.red {
    color: #e21039; }
  .iconWrapper.pink {
    color: #eb5a7b; }
  .iconWrapper.magenta {
    color: #c739a8; }
  .iconWrapper.green {
    color: #8bbb39; }
  .iconWrapper.success-green {
    color: #1eac71; }
  .iconWrapper.purple {
    color: #a455ff; }
  .iconWrapper.white {
    color: #ffffff; }
  .iconWrapper.blue {
    color: #007BA8; }
  .iconWrapper.brightBlue, .iconWrapper.bright-blue {
    color: #19b6f3; }
  .iconWrapper.lightBlue, .iconWrapper.light-blue {
    color: #10b5f5; }
  .iconWrapper.grey {
    color: #666; }
  .iconWrapper.light-grey {
    color: #dfe0e1; }
  .iconWrapper.off-white {
    color: #f5f7f9; }
  .iconWrapper.black {
    color: #010202; }
  .iconWrapper.dark-grey {
    color: #2a2f35; }
  .iconWrapper.blue-black {
    color: #2a2f35; }
  .iconWrapper.disabled-grey {
    color: #c5c8ca; }
  .iconWrapper.neutral-500 {
    color: #72757E; }
  .iconWrapper.neutral-600 {
    color: #4C4F56; }
  .iconWrapper.neutral-700 {
    color: #2A2B30; }
  .iconWrapper.hover-grey:hover {
    color: #666; }
  .iconWrapper.hover-blue:hover {
    color: #007BA8; }
  .iconWrapper.hover-red:hover {
    color: #e21039; }
  .iconWrapper.right-icon {
    margin-left: auto; }
  .iconWrapper.is-rotating .cr__icon {
    animation: isRotating 1.1s ease-in-out infinite; }

@keyframes isRotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.inputContainer {
  position: relative;
  margin-bottom: 8px; }
  .inputContainer__input {
    padding: 4px 15px;
    transition: 0.1s all ease-in;
    border: 1px solid #dfe0e1;
    border-radius: 4px;
    background: #ffffff;
    color: #2a2f35;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px; }
    .inputContainer__input--has-value {
      border: 1px solid #666; }
    .inputContainer__input--disabled {
      user-select: none;
      pointer-events: none;
      border: 1px solid #c5c8ca;
      color: #c5c8ca; }
    .inputContainer__input:focus, .inputContainer__input:active {
      border: 1px solid #007BA8; }
    .inputContainer__input--read-only {
      border-color: #f5f7f9;
      background-color: #f5f7f9; }
    .inputContainer__input--readOnly {
      border: 1px solid #f5f7f9;
      background: #f5f7f9; }
      .inputContainer__input--readOnly:focus {
        border: 1px solid #f5f7f9; }
  .inputContainer.green .inputContainer__input:focus, .inputContainer.green .inputContainer__input:active {
    border: 1px solid #8bbb39; }
  .inputContainer .input__icon {
    color: #666; }
  .inputContainer__label {
    display: inline-block;
    padding-bottom: 6px;
    color: #2a2f35;
    font-weight: 300;
    line-height: 1;
    user-select: none;
    cursor: default; }
  .inputContainer--error input:focus {
    border: 1px solid #e21039; }
  .inputContainer--error input {
    border: 1px solid #e21039; }
  .inputContainer--error .icon {
    color: #e21039; }
  .inputContainer.disabled input {
    border: 1px solid #f5f7f9; }
  .inputContainer__error {
    position: absolute;
    right: 16px;
    color: #e21039;
    font-size: 14px;
    font-weight: 300; }
  .inputContainer .required {
    color: #e21039;
    font-weight: 700; }
  .inputContainer--has-button input {
    padding-right: 70px; }
  .inputContainer--has-button .button,
  .inputContainer--has-button .cr__button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: 0 4px 4px 0; }
    .inputContainer--has-button .button .icon,
    .inputContainer--has-button .cr__button .icon {
      padding: 0; }
  .inputContainer--has-button .inputContainer__input--has-value ~ .button,
  .inputContainer--has-button .inputContainer__input--has-value ~ .cr__button {
    background: #007BA8; }
    .inputContainer--has-button .inputContainer__input--has-value ~ .button:hover,
    .inputContainer--has-button .inputContainer__input--has-value ~ .cr__button:hover {
      background: #00455E; }
  .inputContainer--has-button .inputContainer__input--disabled ~ .button,
  .inputContainer--has-button .inputContainer__input--disabled ~ .cr__button {
    background: #c5c8ca; }
    .inputContainer--has-button .inputContainer__input--disabled ~ .button:hover,
    .inputContainer--has-button .inputContainer__input--disabled ~ .cr__button:hover {
      background: #c5c8ca; }
  .inputContainer--has-button.green .inputContainer__input--has-value ~ .button,
  .inputContainer--has-button.green .inputContainer__input--has-value ~ .cr__button {
    background: #8bbb39; }
    .inputContainer--has-button.green .inputContainer__input--has-value ~ .button:hover,
    .inputContainer--has-button.green .inputContainer__input--has-value ~ .cr__button:hover {
      background: #729932; }

.inputContainer__input::-webkit-input-placeholder {
  color: #666; }

.inputContainer__input:-moz-placeholder {
  color: #666; }

.inputContainer__input::-moz-placeholder {
  color: #666; }

.inputContainer__input:-ms-input-placeholder {
  color: #666; }

@-moz-keyframes fade {
  25% {
    opacity: 1; }
  55%,
  65% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade {
  25% {
    opacity: 1; }
  55%,
  65% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.logoWrap.small {
  width: 38.1px;
  height: 44px; }

.logoWrap.medium {
  width: 76.2px;
  height: 88px; }

.logoWrap.large {
  width: 114.3px;
  height: 132px; }

.logo.pink .loader__1 {
  fill: #F5979B;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.75s; }

.logo.pink .loader__2 {
  fill: #F17F84;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.50s; }

.logo.pink .loader__3 {
  fill: #EE5D6F;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.25s; }

.logo.pink .loader__4 {
  fill: #ED4062;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s; }

.logo.blue .loader__1 {
  fill: #8AC7D8;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.75s; }

.logo.blue .loader__2 {
  fill: #6DBBD1;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.50s; }

.logo.blue .loader__3 {
  fill: #50AEC9;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.25s; }

.logo.blue .loader__4 {
  fill: #33A2C1;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s; }

.logo.green .loader__1 {
  fill: #BAD58E;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.75s; }

.logo.green .loader__2 {
  fill: #AACC72;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.50s; }

.logo.green .loader__3 {
  fill: #9AC355;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.25s; }

.logo.green .loader__4 {
  fill: #8BBB39;
  transition: 1.25s;
  opacity: 1;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s; }

.cr__metric__value {
  margin-bottom: 4px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 32px;
  line-height: 1.0; }

.cr__metric__label {
  display: flex;
  align-items: flex-end; }
  .cr__metric__label__icon {
    margin-right: 8px; }
  .cr__metric__label__text {
    font-size: 14px;
    line-height: 16px; }

.cr__metrics {
  display: flex;
  justify-content: space-between; }
  .cr__metrics--horizontal {
    flex-direction: row; }
    .cr__metrics--horizontal > :nth-child(n + 2) {
      margin-left: 24px; }
  .cr__metrics--vertical {
    flex-direction: column; }
    .cr__metrics--vertical > :nth-child(n + 2) {
      margin-top: 24px; }

.cr-table__dateCell span {
  display: block; }

.cr-table__dateCell__date {
  color: #2A2F35;
  font-size: 14px;
  line-height: 20px; }

.cr-table__dateCell__time {
  color: rgba(102, 102, 102, 0.8);
  font-size: 12px;
  line-height: 20px; }

.cr-table__link {
  display: flex;
  align-items: center;
  color: #007BA8;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 18px;
  cursor: pointer; }
  .cr-table__link--icon {
    display: inline;
    margin-left: 4px; }

.cr-table__truncatedTextCell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }
  @supports (-webkit-line-clamp: 2) {
    .cr-table__truncatedTextCell {
      display: -webkit-box;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal; } }

.photoStrip {
  display: block;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }
  .photoStrip__containTitles {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    .photoStrip__containTitles h2 {
      margin: 0 0 8px 0;
      font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; }
  .photoStrip__containImgs {
    display: flex; }
  .photoStrip__hoverOverlay {
    width: 100%;
    height: 100%;
    transition: 0.3s all ease-in-out;
    background: transparent; }
  .photoStrip__backgroundImg {
    margin: 0 6px;
    background-color: #8a8c90;
    background-position: center;
    background-size: cover; }
    .photoStrip__backgroundImg:first-of-type {
      margin-left: 0; }
    .photoStrip__backgroundImg:last-of-type {
      margin-right: 0; }
  .photoStrip__link {
    margin-bottom: 8px; }
  .photoStrip:hover .photoStrip__hoverOverlay, .photoStrip:focus .photoStrip__hoverOverlay {
    background: rgba(0, 0, 0, 0.6); }

.radioList {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .radioList__label {
    padding-bottom: 8px;
    color: #666;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px; }
  .radioList__list--item {
    width: 100%;
    padding: 6px 0;
    cursor: pointer; }
    .radioList__list--item:focus, .radioList__list--item:active {
      outline: none; }
  .radioList__itemWrapper {
    display: flex;
    justify-content: space-between; }
    .radioList__itemWrapper.disabled-grey {
      color: #c5c8ca; }
  .radioList__iconWrapper {
    display: flex;
    align-items: center; }
  .radioList__optionIcon {
    margin-right: 6px; }
  .radioList__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%; }

.radioToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #007BA8;
  border-radius: 4px;
  cursor: pointer; }
  .radioToggle__toggle {
    display: flex;
    justify-content: center;
    width: 100%; }
    .radioToggle__toggle:nth-of-type(2n + 1) {
      border-right: 1px solid #007BA8; }
  .radioToggle__input {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden; }
    .radioToggle__input:checked + label {
      background-color: #007BA8;
      color: #ffffff; }
  .radioToggle__label {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 36px;
    margin: 0;
    padding: 2px 4px;
    color: #007BA8;
    font-size: 10px;
    letter-spacing: 0.06px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase; }

.ratingComponent {
  display: flex; }
  .ratingComponent .ratingWrapper {
    position: relative; }
  .ratingComponent .half {
    position: absolute;
    top: 0;
    z-index: 1;
    background: #ffffff;
    overflow: hidden; }
    .ratingComponent .half svg {
      overflow: visible; }
  .ratingComponent .half-bg {
    position: absolute; }

.rifficonWrapper {
  position: relative; }
  .rifficonWrapper.animate svg {
    animation: driving 1s ease-in-out infinite alternate; }

@keyframes driving {
  0% {
    transform: translateX(-1px) translateY(-2px); }
  25% {
    transform: translateX(0) translateY(0); }
  50% {
    transform: translateX(-3px) translateY(-3px); }
  75% {
    transform: translateX(0) translateY(0); }
  100% {
    transform: translateX(-1px) translateY(-2px); } }

.cr-selectedItemList {
  width: 100%; }
  .cr-selectedItemList__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 6px 12px;
    border: 1px solid #8A8C90;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #ffffff;
    color: #2a2f35;
    font-size: 16px;
    line-height: 20px;
    word-break: break-word; }
    .cr-selectedItemList__item--icon {
      display: inline-block;
      position: relative;
      margin: -7px;
      padding: 7px;
      cursor: pointer; }
      .cr-selectedItemList__item--icon .cr__icon {
        fill: #8A8C90; }
        .cr-selectedItemList__item--icon .cr__icon:hover {
          fill: #646669; }
  .cr-selectedItemList__removeAllButton {
    margin-top: 12px;
    margin-left: auto; }
    .cr-selectedItemList__removeAllButton .iconWrapper {
      margin-top: 1px; }

.rangeSlider__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.rangeSlider__value {
  min-width: 36px;
  margin: 0;
  padding-left: 8px;
  color: #2a2f35;
  font-size: 14px;
  font-weight: 300;
  text-align: right; }

.rangeSlider__label {
  display: block;
  padding-bottom: 16px; }

.rangeSlider {
  position: relative;
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

.rangeSlider:after {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  padding: 10px;
  content: '';
  cursor: pointer; }

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none; }

.rangeSlider:focus {
  outline: none; }

.rangeSlider::-ms-track {
  width: 100%;
  /* Hides the slider so custom styles can be added */
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer; }

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  border-radius: 50%;
  background: #007BA8;
  cursor: pointer; }

.rangeSlider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border: 1px solid #007BA8;
  border-radius: 50%;
  background: #007BA8;
  cursor: pointer; }

input[type=range]::-ms-thumb {
  width: 18px;
  height: 18px;
  border: 1px solid #007BA8;
  border-radius: 50%;
  background: #007BA8;
  cursor: pointer; }

.rangeSlider::-webkit-slider-runnable-track {
  width: 100%;
  height: 2.5px;
  border-radius: 1.3px;
  background: #666;
  cursor: pointer; }

.rangeSlider:focus::-webkit-slider-runnable-track {
  background: #666; }

.rangeSlider::-moz-range-track {
  width: 100%;
  height: 4px;
  border-radius: 1.3px;
  background: #666;
  cursor: pointer; }

.rangeSlider::-ms-track {
  width: 100%;
  height: 8.4px;
  border-width: 16px 0;
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer; }

.spinnerLoader {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #ffffff;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  transform: translateX(-10px) translateZ(0);
  border-radius: 50%;
  font-size: 0rem;
  text-indent: -9999em;
  animation: load 1.1s infinite linear; }
  .spinnerLoader--right-pad {
    margin-right: 7px;
    margin-left: 0; }
  .spinnerLoader--no-pad {
    margin: 0; }
  .spinnerLoader.blue {
    border-top: 2px solid rgba(0, 123, 168, 0.2);
    border-right: 2px solid rgba(0, 123, 168, 0.2);
    border-bottom: 2px solid rgba(0, 123, 168, 0.2);
    border-left: 2px solid #007BA8; }
  .spinnerLoader.red {
    border-top: 2px solid rgba(226, 16, 57, 0.2);
    border-right: 2px solid rgba(226, 16, 57, 0.2);
    border-bottom: 2px solid rgba(226, 16, 57, 0.2);
    border-left: 2px solid #e21039; }
  .spinnerLoader.grey {
    border-top: 2px solid rgba(102, 102, 102, 0.2);
    border-right: 2px solid rgba(102, 102, 102, 0.2);
    border-bottom: 2px solid rgba(102, 102, 102, 0.2);
    border-left: 2px solid #666; }
  .spinnerLoader.black {
    border-top: 2px solid rgba(42, 47, 53, 0.2);
    border-right: 2px solid rgba(42, 47, 53, 0.2);
    border-bottom: 2px solid rgba(42, 47, 53, 0.2);
    border-left: 2px solid #2a2f35; }
  .spinnerLoader.green {
    border-top: 2px solid rgba(139, 187, 57, 0.2);
    border-right: 2px solid rgba(139, 187, 57, 0.2);
    border-bottom: 2px solid rgba(139, 187, 57, 0.2);
    border-left: 2px solid #8bbb39; }

.spinner-loader:after {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  content: ''; }

@keyframes load {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.textarea {
  position: relative;
  width: 100%;
  min-width: 200px;
  margin-top: 8px;
  padding: 8px 15px;
  border: 1px solid #666;
  border-radius: 4px;
  color: #2a2f35;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  resize: none; }
  .textarea__container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%; }
    .textarea__container label {
      font-size: 14px;
      font-weight: 300; }
  .textarea:focus {
    border: 1px solid #007BA8;
    outline: none; }
  .textarea.disabled {
    border: 1px solid #c5c8ca; }
  .textarea.error {
    border: 1px solid #e21039; }
  .textarea__inputContainer {
    position: relative;
    /* In order to properly position the clear button within the textArea and have it right justifed:
            - Add more padding to the textarea itself so it does not overlap with input text
            - use position:absolute on icon to right-justify it
        */ }
    .textarea__inputContainer--withClear textarea {
      padding-right: 50px; }
    .textarea__inputContainer--withClear .iconWrapper {
      position: absolute;
      top: calc(50% - 8px);
      right: 20px;
      cursor: pointer; }

.toggle {
  display: block;
  position: relative;
  width: 38px;
  height: 22px;
  padding: 0.02rem;
  border: 1px solid rgba(102, 102, 102, 0.61);
  border-radius: 60px;
  outline: none;
  user-select: none;
  background-color: rgba(102, 102, 102, 0.2);
  cursor: pointer; }
  .toggle:after {
    display: block;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: left 0.3s ease-out;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2);
    content: ''; }
  .toggle__input {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden; }
  .toggle__wrapper {
    display: flex;
    align-items: center; }
  .toggle__label {
    margin: 0 0 6px 0;
    color: #2a2f35;
    font-size: 14px;
    font-weight: 300; }
  .toggle__marker {
    margin-left: 8px;
    color: #2a2f35;
    font-size: 14px;
    font-weight: 300; }
  .toggle__icon {
    position: absolute;
    top: 2px;
    right: 5px;
    z-index: 1;
    width: 11px;
    opacity: 0; }
    .toggle__icon--checked {
      transition: opacity 0.4s ease-out;
      opacity: 1; }

.toggle--checked {
  border: 1px solid #007BA8;
  background-color: #007BA8; }
  .toggle--checked:after {
    left: 16px; }

.toggleButton {
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin: 4px 0px;
  border: 1px solid #dfe0e1;
  border-radius: 24px;
  cursor: pointer; }
  .toggleButton__input {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden; }
  .toggleButton__label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 12px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer; }
  .toggleButton__icon {
    font-size: 14px; }
  .toggleButton--checked {
    border-radius: 24px;
    background: #007BA8;
    color: #ffffff; }
    .toggleButton--checked .iconWrapper {
      color: inherit; }
  .toggleButton .iconWrapper {
    color: #666; }
  .toggleButton.icon-left .iconWrapper {
    padding-right: 8px; }
  .toggleButton.icon-right .iconWrapper {
    display: flex;
    order: 2;
    padding-left: 8px; }
  .toggleButton.disabled {
    pointer-events: none; }
    .toggleButton.disabled .toggleButton__label {
      color: #c5c8ca; }
    .toggleButton.disabled.fill, .toggleButton.disabled.full-width {
      border: 1px solid #dfe0e1;
      background-color: #c5c8ca;
      color: #f5f7f9; }
    .toggleButton.disabled.outline {
      border: 1px solid #dfe0e1;
      background-color: #ffffff;
      color: #c5c8ca; }

.toggleList {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .toggleList__label {
    padding-bottom: 8px;
    color: #666;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px; }
  .toggleList__list {
    display: flex;
    align-items: center;
    height: 32px; }
    .toggleList__list--item {
      display: flex;
      margin-right: 4px;
      cursor: pointer; }
      .toggleList__list--item .cr__icon {
        transition: fill 0.125s ease-in-out;
        font-size: 20px; }
      .toggleList__list--item:hover:not(.isActive) .cr__icon {
        fill: #007BA8; }
      .toggleList__list--item:last-of-type {
        margin-right: 0; }
      .toggleList__list--item:focus, .toggleList__list--item:active {
        outline: none; }
    .toggleList__list--button {
      padding: 0;
      cursor: pointer; }
      .toggleList__list--button:focus, .toggleList__list--button:active {
        outline: none; }
        .toggleList__list--button:focus .cr__icon, .toggleList__list--button:active .cr__icon {
          fill: #007BA8; }
  .toggleList.orientation .toggleList__list--item:last-of-type {
    margin-left: -4px; }
  .toggleList.photoSize .toggleList__list--item:last-of-type {
    margin-left: 2px; }

.tooltip {
  display: inherit;
  position: relative;
  align-items: inherit; }
  .tooltip__textWrapper {
    display: flex;
    position: absolute;
    top: 50%;
    left: 100%;
    z-index: 9;
    width: auto;
    padding: 1px 8px;
    transform: translateY(-50%);
    transition: opacity 0.5s;
    border-radius: 4px;
    background-color: #2a2f35;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    box-shadow: 0.3px 2px 5px 0 rgba(0, 0, 0, 0.25);
    opacity: 0; }
  .tooltip__text {
    display: block;
    margin: 0;
    color: #ffffff;
    font-size: 14px;
    white-space: nowrap; }
  .tooltip.isOpen > .tooltip__textWrapper {
    opacity: 1; }
  .tooltip.right > .tooltip__textWrapper {
    right: auto;
    left: calc(100% + 8px); }
  .tooltip.left > .tooltip__textWrapper {
    right: 100%;
    left: auto; }
  .tooltip.topLeft > .tooltip__textWrapper {
    top: -17px;
    right: auto;
    bottom: auto;
    left: 0; }
  .tooltip.topCenter > .tooltip__textWrapper {
    top: -17px;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.topRight > .tooltip__textWrapper {
    top: -17px;
    right: 0;
    bottom: auto;
    left: auto; }
  .tooltip.bottomCenter > .tooltip__textWrapper {
    display: flex;
    top: auto;
    bottom: -17px;
    left: 50%;
    z-index: 99;
    justify-content: center;
    transform: translate(-50%, 50%); }

.videoContainer {
  position: relative;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }
  .videoContainer__withControls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .videoContainer__withControls-inner {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      max-width: 100%; }
  .videoContainer__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .videoContainer video {
    display: block;
    max-width: 100%;
    max-height: 100%; }
  .videoContainer__controls {
    display: flex;
    position: relative;
    z-index: 3;
    align-items: center;
    width: 100%;
    background: black;
    color: white; }
    .videoContainer__controls .control {
      flex-grow: 0;
      padding: 10px;
      cursor: pointer; }
    .videoContainer__controls .time {
      padding: 10px; }
  .videoContainer .progressbar {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    margin: 0 5px;
    padding: 0; }
    .videoContainer .progressbar .active-bar {
      position: absolute;
      top: 50%;
      left: 0;
      height: 4px;
      transform: translateY(-50%);
      border-radius: 2px;
      background: #19b6f3; }
      .videoContainer .progressbar .active-bar:after {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #19b6f3;
        content: ''; }
    .videoContainer .progressbar .total-bar {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background: white; }
