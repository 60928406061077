@import './variables';

html {
    font-family: $base-font;
    font-size: 62.5%;
}

body {

    font-family: $base-font;
    font-size: 1.4rem;
    line-height: 1.2;
}

a {
    color: inherit;
}

h1 {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: normal;
}
h2 {

    font-size: map-get($font-size, 'xl');
    font-weight: 300;
    line-height: normal;
}

h3 {
    font-size: map-get($font-size, 'xxl');
    font-weight: 700;
}

h4 {
    font-size: 1.8rem;
    line-height: normal;
}

h5 {
    font-family: $base-font;
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

p {
    color: $dark-grey;
    font-family: $base-font;
    font-size: map-get($font-size, 'md');
    font-weight: normal;
    line-height: 1.43;
    a {
        color: $text-900;
        font-weight: 400;
    }
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h6 {
    margin: 0;
    font-family: $display-font;
}


a {
    color: inherit;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
    }
}

small {
    font-size: rem(12);
}
