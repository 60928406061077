@import '../../../../styles/variables.scss';

.story_embed {
  padding: map-get($spacer, 'md');
  border: 1px solid $color-neutral-200;
  border-radius: 10px;

  &__title_container {
    display: flex;
    gap: map-get($spacer, 'xxs');
    margin: 0 0 map-get($spacer, 'xs');
  }

  &__buttons {
    &--copy_id {
      margin-left: map-get($spacer, 'sm');
    }
  }

  &__title {
    font-weight: 700;
  }

  &__description,
  &__draft {
    margin: 0 0 map-get($spacer, 'sm') 0;
    color: $color-neutral-600;
  }
}