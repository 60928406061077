@import '../../../styles/variables.scss';

.page {
  &__content {
    width: 100%;
    min-width: $page-min-width;
    max-width: $page-max-width;
    margin: map-get($spacer, xxl) auto;
    padding: 0 $page-w-padding;
  }
}
