@import '../../../styles/variables.scss';

.spinner {
  height: 30px;
  width: 30px;
  animation: spin 2s linear infinite;

  &__bars {
    width: 6%;
    height: 16%;
    background: $color-neutral-700;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
  .bar1 {
    transform: rotate(0deg) translate(0, -130%);
    opacity: 1;
  }

  .bar2 {
    transform: rotate(45deg) translate(0, -130%);
    opacity: 0.9;
  }

  .bar3 {
    transform: rotate(90deg) translate(0, -130%);
    opacity: 0.7;
  }
  .bar4 {
    transform: rotate(135deg) translate(0, -130%);
    opacity: 0.6;
  }
  .bar5 {
    transform: rotate(180deg) translate(0, -130%);
    opacity: 0.5;
  }
  .bar6 {
    transform: rotate(220deg) translate(0, -130%);
    opacity: 0.4;
  }
  .bar7 {
    transform: rotate(260deg) translate(0, -130%);
    opacity: 0.3;
  }
  .bar8 {
    transform: rotate(310deg) translate(0, -130%);
    opacity: 0.2;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
