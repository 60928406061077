@use "sass:math";
@import '../../../styles/variables.scss';

.select {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: math.div(map-get($spacer, "xs"), 2);
  font-size: map-get($font-size, 'md');

  &__label {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 30px) scale(1);
    line-height: map-get($line-height, 'md');
    color: $color-neutral-500;

    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.animated {
      color: $color-neutral-600;
      transform: translate(0) scale(0.867);
      line-height: map-get($line-height, 'sm');
      transform-origin: top left;
    }

    &.required {
      &:after {
        content: '*';
      }
    }
  }

  .rs__select-component {
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid $color-neutral-300;

    &:hover {
      border-bottom: 1px solid $color-neutral-700;
    }

    &__inner__control {
      min-height: 34px;
      margin-top: 24px;
      border-radius: 0;
      padding-bottom: map-get($spacer, "xxs");
      cursor: pointer;

      display: flex;
      gap: map-get($spacer, "xxs");

      &:hover,
      &--is-focused {
        box-shadow: none;
      }

      &--is-focused {
        .rs__select-component__inner__placeholder {
          display: block;
          color: $color-neutral-400;
        }
      }

      &--menu-is-open {
        .dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__inner__value-container {
      padding: 0;

      &__single-value {
        margin: 0;
      }
    }

    &__inner__placeholder {
      display: none;
    }

    &__inner__multi-value {
      border: 1px solid $color-neutral-200;
      background-color: $color-neutral-100;
      border-radius: 41px;
      color: $color-neutral-700;
      font-weight: 500;
      line-height: map-get($line-height, 'sm');
      padding: map-get($spacer, "xxs") map-get($spacer, 'xs');

      display: flex;
      align-items: center;
      gap: map-get($spacer, "xxs");

      &__label {
        padding: 0;
      }

      &__remove {
        color: $color-neutral-600;
        align-self: center;

        &:hover {
          background-color: transparent;
          color: $color-neutral-700;
          cursor: pointer;
        }
      }
    }

    &__inner__single-value {
      font-size: map-get($font-size, 'md');
      line-height: map-get($line-height, 'md');

      margin: 0;
      color: $text-900;
    }

    &__inner__indicators {
      display: flex;
      gap: map-get($spacer, "xxs");
    }

    &__inner__menu {
      background: $color-bg-100;
      border: 1px solid $color-neutral-100;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
      border-radius: map-get($spacer, "xxs");
    }

    &__inner__option {
      color: $color-neutral-700;
      cursor: pointer;
      background-color: transparent;

      &:active {
        background-color: $color-primary-0;
      }

      &--is-focused {
        background-color: $color-primary-0;
        color: $color-neutral-700;
      }

      &--is-selected {
        background-color: $color-primary-100;
      }

      &--is-disabled {
        background-color: none;
        color: $color-neutral-600;
      }
    }
  }

  &__help-text {
    color: $color-neutral-600;
    max-width: calc(100% - 20px);
  }

  &__error-message {
    color: $color-error-700;
  }

  &.focused {
    .rs__select-component {
      border-bottom: 1px solid $color-neutral-700;
    }
  }

  &.invalid {
    .select__label {
      color: $color-error-700;
    }

    .rs__select-component {
      border-bottom: 1px solid $color-error-700;

      &:hover,
      &--is-focused {
        border-bottom: 1px solid $color-error-700;
        box-shadow: none;
      }
    }
  }

  .clear-indicator, .dropdown-indicator {
    fill: $color-neutral-600;
  }
}
