@import '../../../styles/variables.scss';
@import '../../../styles/mixins/link_styles.scss';

.gallery_list_grid_card {
  margin-bottom: 16px;
  border: 1px solid $grey;
  border-radius: 4px;
  &--column,
  > .gallery_card_chip--container {
    margin: 16px 0;
    padding: 0 16px;
    color: $blue-black;
    word-break: break-word;
    &:first-child {
      border-right: 1px solid $grey;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      grid-column-start: 6;
      grid-column-end: 7;
    }
  }

  &--gallery_title {
    display: inline-block;
    font-weight: 500;
    margin-bottom: map-get($spacer, 'xs');
    font-size: map-get($font-size, 'lg');
  }

  &--copy_to_clipboard {
    padding: 0;
    background-color: transparent;
  }

  &__admin-flag {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 155px;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: $color-neutral-100;
    color: $black;
    font-size: 14px;
    font-weight: normal;
  }

  &--menu {
    &_wrapper {
      align-self: start;
      position: relative;

      .menu {
        top: 100%;
        z-index: 1;
      }
    }
  }
}
