@import '../../../styles/variables.scss';
@import '../../../styles/mixins/link_styles.scss';

.chip {
  display: inline-flex;
  align-items: center;
  padding: map-get($spacer, 'xxs') map-get($spacer, 'xs');
  color: $black;
  font-size: map-get($font-size, 'xs');
  font-weight: normal;
  border: 1px solid $color-neutral-300;
  border-radius: 100px;
  gap: map-get($spacer, 'xxs');

  &.dark {
    background-color: $color-neutral-100;
  }
}
