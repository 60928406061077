@import '../../../styles/variables.scss';

.carousel {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    li {
        figure {
            position: relative;
            margin: 0;

            .story--overlay {
                padding: 8px;
            }
        }
    }

    &__title {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: flex-end;
        padding: 12px;
        border-radius: 8px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
        p {
            margin: 0;
            color: white;
            font-size: 12px;
            overflow: hidden;
            word-break: break-word;
        }
    }
}
