@import '../../../styles/variables.scss';
.gallery_preview {
  &__title {
    margin: 10px 0 8px;
    color: $blue-black;
    font-weight: 600;
  }

  &__description {
    margin: 0;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 220px;
  }

  &__generate {
    display: flex;
    position: relative;
    min-height: 150px;
    margin: 16px 0 26px;

    .carousel {
      margin: 0;

      li {
        width: 100%;
        margin: 0 8px;
        border-radius: 8px;

        &:last-of-type {
          margin: 0 0 0 8px;
        }

        &:first-of-type {
          margin: 0 8px 0 0;
        }

        .story {
          max-height: 220px;
          max-width: 128.5px;
          min-width: 128.5px;
          @media #{$tablet} {
            min-width: 72px;
          }
          @media #{$mobile} {
            min-width: 62.5px;
          }
        }
      }
    }
    &--empty-card {
      width: 100%;
      max-width: 100%;
      height: 220px;
      margin: 0 8px;
      padding-top: 35%;
      border-radius: 8px;
      background-color: $medium-light-grey;

      &:first-of-type {
        margin: 0 8px 0 0;
      }

      &:last-of-type {
        margin: 0 0 0 8px;
      }
    }
    &__floatingText {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      padding: 8px;
      transform: translate(-50%, -50%);
      border-radius: 4px;
      background-color: $white;
      text-align: center;
      @media only screen and (min-width: 900px) {
        white-space: nowrap;
      }

      p {
        margin: 0;
      }

      .cr__icon-warning {
        margin-right: 8px;

        svg {
          fill: $color-warning-900;
        }
      }
    }

    @media only screen and (min-width: 1300px) {
      min-height: 220px;
    }
  }

  &__matched_stories {
    display: inline-block;
    font-size: 1.2rem;
    margin: 12px;
    @media #{$tablet} {
      min-width: 72px;
    }
  }
  .story--overlay {
    background-image: linear-gradient(
      180deg,
      transparent 50%,
      rgba(0, 0, 0, 0.7)
    );
  }
}
