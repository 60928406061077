@import '../../../styles/variables.scss';

.assign_story_modal {
  .modal__close-button {
    display: none;
  }
  .modal__content-container {
    padding: 0;
  }
  [role='dialog'] {
    width: 520px;
    border-radius: map-get($spacer, 'xs');
    padding: map-get($spacer, 'lg');
    gap: map-get($spacer, 'xs');
    overflow-y: visible;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacer, 'md');
  }

  h3 {
    margin: 0;
    font-size: map-get($font-size, 'xl');
    line-height: map-get($line-height, 'xl');
  }
}

.modal.basic [role='dialog'] {
  padding: map-get($spacer, 'lg');
}
