@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins/legacy_buttons.scss';

.gallery_list_grid_card_delete_modal {
  &--list {
    list-style: disc;
    padding-left: map-get($spacer, 'md');
    line-height: 2;

    li {
      line-height: 1.43;
      
      &:not(:last-child){
          padding-bottom: map-get($spacer, 'xs');
      }
    }
    
  }

  &--snackbar--undo {
    button {
      text-decoration: underline;
      padding: 0;
      font-weight: 700;
    }
  }

  .modal__content-container {
    p {
      margin-top: 0;

      &:last-of-type {
        margin-bottom: 6px;
      }
    }
  }

  // TODO: update after button implementation matches design system (SNET-1595)
  &__accept-btn {
    @include legacy-button(
      $bgColor: $color-error-700,
      $hoverBgColor: $color-error-900,
      $padding: 17px 40px,
      $color: $text-100
    );
  }
}
