@import '../../../styles/variables.scss';
@import '../../../styles/mixins/form_footer.scss';

.gallery_form {
  min-width: 300px;
  margin-right: 25px;

  h2 {
    margin: 43px 0 12px;
    color: $blue-black;
    font-family: $display-font;
    font-weight: bold;
  }
  &--configure-gallery {
    margin: 0 0 map-get($spacer, 'sm');
    line-height: 1.43;
    font-size: map-get($font-size, 'md');
  }

  &__footer {
    @include formFooter();
  }

  .input-component,
  .select,
  .radio-group {
    margin-bottom: map-get($spacer, 'md');
  }

  #galleryLocations {
    margin-top: 0px;
  }

  .select-component:nth-of-type(4) {
    margin-bottom: map-get($spacer, 'sm');
  }

  &__radio-group {
    margin-top: map-get($spacer, xl );
  }
}
