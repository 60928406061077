@import '../../../../styles/variables.scss';
.download_story {
    padding: map-get($spacer, 'md' );
    border: 1px solid $color-neutral-200;
    border-radius: 10px;

    &__title_container {
        display: flex;
        margin: 0 0 map-get($spacer, 'xs' );
        gap: map-get($spacer, 'xxs' );
    }

    &__title {
        font-weight: 700;
    }

    &__description {
        margin: 0 0 map-get($spacer, 'sm' ) 0;
        color: $color-neutral-600;
    }
}
