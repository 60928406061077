@import '../../../styles/variables.scss';
@mixin search_form_grid-container {
  margin: 0;

  display: grid;
  grid-template-columns: [start] 200px auto [end];
  grid-template-rows: auto;
  gap: map-get($spacer, 'md');

  h5 {
    color: $blue-black;
    font-weight: 400;
  }
}

.stories_search_form {
  color: $blue-black;
  @include search_form_grid-container;

  &--status, &--visibility {
    h5 {
      margin: 0 0 map-get($spacer, 'xs');
    }
  }

  &--buttons,
  &--creator_type {
    grid-column-start: start;
    grid-column-end: end;
  }

  &--buttons {
    justify-self: flex-end;
    display: flex;
    gap: map-get($spacer, 'xs');
  }
  .toggle_button {
    margin-right: map-get($spacer, 'xs');
  }

  .toggle_button__container {
    .cr__icon-approve {
      transform: scale(1.5);
    }
  }

  .toggle_button__container:last-of-type {
    .toggle_button {
      margin-right: 0;
    }
  }
  &--dynamic-filters {
    grid-column-start: start;
    grid-column-end: end;

    .dynamic_filters {
      @include search_form_grid-container;
      grid-template-columns: [start] 200px minmax(auto, 400px) 12px [end];
      gap: map-get($spacer, 'xs');
    }
  }

  &--add_filters {
    margin: map-get($spacer, 'xs') 0;
  }
}
