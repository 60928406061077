@import '../../../styles/variables.scss';
@import '../../../styles/mixins/_general.scss';

.navigation__innerWrapper {
    .cr-tooltip__buttonWrapper {
        display: flex;
        align-items: center;
        
        .cr__button {
            @media #{$mobile} {
                padding: 4px 0;
            }
            display: flex;
            align-items: center;
            height: 100%;
            transition: none;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            &:hover {                
                background-color: rgba($color-bg-100, 0.2);
                border-bottom: 5px solid $color-primary-100;
                border-top: 5px solid transparent;
                cursor: pointer;
            }

            // when dropdown is open, set active background color
            &[aria-describedby*='mui'] {
                background-color: rgba($color-bg-100, 0.2);
                border-bottom: 5px solid $color-primary-400;
                border-top: 5px solid transparent;
            }
            // when closing dropdown, hide dropdown to smoothen unmounting
            &:not([aria-describedby*='mui']) ~ .cr-tooltip {
                display: none;
            }

            @include outline-styles($focusBorderRadius: 5px, $focusBorderGutter: -2px, $borderColor: $color-bg-100);
            
            // overriding position of focus border because it needs
            // different values to achieve the required design
            &:focus {
                &:after,
                &-visible:after {
                    top: -5px;
                    bottom: -6px;
                }
            }
        }
        .cr__icon-angle-down {
            margin-left: 10px;
        }
        .cr-tooltip {
            margin-top: 15px;
            border-radius: 4px;
            background-color: $blue-black;
            color: white;
            pointer-events: auto;
            @media #{$mobile} {
                width: auto !important;
            }
            &__tooltip {
                width: 100%;
                margin: 8px 0;
                padding: 0;
                transition: none !important;
                border-radius: 0;
                background: transparent;
                color: white;
                font-family: $base-font;
            }
        }
        .utility_menu {
            &--button_container {
                display: flex;
                gap: map-get($spacer, 'xs');
                align-items: center;
            }

            &--button {
                margin: 0;
                color: white;
                line-height: 32px;
                @media #{$mobile} {
                    display: none;
                }
            }

            &--publisher,
            &--logout {
                border-left: 5px solid #2a2f35;
                a {
                    display: inline-block;
                    width: 100%;
                    padding: 6px 16px;
                }
                &:hover:not(.selected) {
                    border-left: $color-primary-400;
                    a,
                    button {
                        border-left: 5px solid $color-primary-100;
                        background-color: rgba($color-bg-100, 0.2);
                        color: white;
                        cursor: pointer;
                    }
                }
            }

            &--publisher,
            &--logout {
                p,
                button {
                    width: 100%;
                    margin: 0;
                    border-radius: 0;
                    background-color: transparent;
                    color: white;
                    font-size: map-get($font-size, 'sm' );
                    text-align: left;
                    padding: 9px 16px;
                    border: none;
                    &:hover {
                        opacity: 1;
                    }
                }

            }
            
            &--publisher {
                &_label {
                    display: flex;
                    align-items: center;
                    gap: map-get($spacer, 'xs');
                }
            }
            
            &--logout,
            &--support,
            &--account {
                line-height: 32px;
            }

            &--publisher {
                &.selected {
                    border-left: 5px solid $color-primary-400;
                    &:hover {
                        cursor: not-allowed;
                    }
                }
            }
            &--publishers {
                padding-bottom: 8px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);

                max-height: calc(31px * 10);
                overflow: auto;
            }
            &--support, &--account {
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                font-size: map-get($font-size, 'sm' );
                .navigation__innerWrapper--support,
                .navigation__innerWrapper--account {
                    a {
                        display: block;
                        padding: 0 16px;
                        border-left: 5px solid $color-bg-900;
                    }
                }
                &:hover {
                    .navigation__innerWrapper--support,
                    .navigation__innerWrapper--account {
                        a {
                            border-color: $color-primary-100;
                            background-color: rgba($color-bg-100, 0.2);
                        }
                    }
                }
            }

            &--admin {
                margin: 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                color: white;
                line-height: 32px;
                font-size: map-get($font-size, 'sm' );
                @media #{$mobile} {
                    display: none;
                }
                .navigation__innerWrapper--admin {
                    // padding-bottom: 8px;
                    a {
                        display: block;
                        padding-left: 16px;
                        border-left: 5px solid $color-bg-900;
                    }
                }
                &:hover {
                    .navigation__innerWrapper--admin {
                        a {
                            border-color: $color-primary-100;
                            background-color: rgba($color-bg-100, 0.2);
                        }
                    }
                }
            }
        }
        .avatar-component {
            margin-left: 10px;
            img {
                border-color: $white;
            }
        }
    }

    #search-component__admin_publisher {
        margin-bottom: $space-xs;
        .cr__icon-search {
            svg {
                fill: $white;
            }
        }
        .search__btn-clear svg path {
            fill: $white !important;
        }

        input {
            width: 200px;
            border: none;
            border-radius: 0;
            background-color: $blue-black;
            color: $white;
            box-shadow: none;
        }
    }

    .creator_services__chip {
        padding: map-get($spacer, 'xxs') map-get($spacer, 'xs');
        color: $text-100;
        font-size: map-get($font-size, 'xs');
        font-weight: normal;
        border-radius: 100px;
        background-color: $color-neutral-600;
    }
}
