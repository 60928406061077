@import '../../../styles/variables.scss';

.error_state {
    &--error {
        z-index: $z-index-bottom;
        position: relative;
        margin-top: 150px;
        text-align: center;
        &_message {
            position: absolute;
            z-index: $z-index-middle;
            text-align: center;
            margin: 0 auto;
            display: block;
            width: 100%;
            h1 {
                font-family: $display-font;
                font-size: 2.4rem;
                font-weight: bold;
                margin: 18px 0;
            }
        }

        .rifficonWrapper {
          z-index: $z-index-top;
        }

        p {
            width: 100%;
            font-family: $base-font;
            font-size: 1.4rem;
            margin: 0;
        }
    }
    &--ellipsis {
        position: absolute;
        right: 50%;
        z-index: $z-index-bottom;
        width: 233px;
        height: 233px;
        transform: translate(50%, -45%);
        border-radius: 50%;
        background: $off-white;
    }
}
