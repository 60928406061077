@import '../../../styles/variables.scss';
.storiesListPage {
    .page__content {
        .story_list {
            &--actions + .empty_state {
                /* centering the component according to figma
                    for the story search empty state only in the
                    main story list page */
                margin-top: 174px;
            }
        }
    }
}
