@import '../variables.scss';
@import '../functions.scss';

@mixin textStyle($fontSize, $fontWeight, $lineHeight, $fontStyle: null) {
  margin: 0;
  padding: 0;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  font-style: $fontStyle;
}
