@import '../../../styles/variables.scss';



[role='tooltip'] {
  .tooltip {
    &__tooltip-body {
      display: flex;
      align-self: stretch;
      justify-content: flex-start;
      min-width: 219px;
      padding: 20px;
      border-radius: 10px;
      background-color: $color-bg-100;
      box-shadow: 0px 2px 16px 3px rgba(0, 0, 0, 0.15);
      border: 1px solid $color-neutral-100;
      
      .tooltip__arrow {
        color: $color-bg-100;
        font-size: 18px;
      }
    }
    
    &__tooltip-gif {
      display: flex;
      min-width: 460px;
      height: 300px;
      background: $color-primary-100;
      border-radius: 10px;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);


      &-desktop {
        .tooltip_gif__video {
          width: 414px;
          height: 230px;
        }
      }

      &-mobile {
        .tooltip_gif__video {
          width: 118px;
          height: 255px;
        }
      }
    }
  }

  .tooltip__tooltip-body.MuiTooltip-tooltipPlacementTop,
  .tooltip__tooltip-body.MuiTooltip-tooltipPlacementBottom {
    margin: 14px -3px;
  }
  .tooltip__tooltip-gif.MuiTooltip-tooltipPlacementTop,
  .tooltip__tooltip-gif.MuiTooltip-tooltipPlacementBottom {
    margin-top: map-get($spacer, 'xs');
  }

  .MuiTooltip-tooltipPlacementRight,
  .MuiTooltip-tooltipPlacementLeft {
    margin: 2px 14px;
  }
}

.tooltip {
  &__container {
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    gap: map-get($spacer, 'xxs');
  }

  &_gif {
    &__container {
      margin: auto;
    }
  
    &__video {
      border-radius: 5px;
    }
  }
}
