@import '../../../styles/variables';
@import '../../../styles/mixins/legacy_buttons.scss';

$bottom-padding: 50px;

.ScrollToTopButton {
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: flex-end;
  padding-bottom: $bottom-padding;

  &__wrapper {
    max-width: 100vw;
  }

  button {
    // This positioning is necessary, otherwise it's possible for the first button click
    // to just bring focus to the button and a second click is required to trigger the onClick function.
    position: absolute;
    bottom: $bottom-padding;
    @include legacy-button(
      $bgColor: $color-bg-100,
      $fontSize: map-get($font-size, 'sm'),
      $padding: 10px 15px,
      $lineHeight: 18px,
      $borderColor: transparent
    );
    gap: 5px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);

    &:hover,
    &:active {
      border: 1px solid $text-900;
    }

    &:focus {
      border: 2px solid $text-900;
      &:after {
        content: none;
      }
    }
  }
}
