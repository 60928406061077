@import '../../../styles/variables.scss';

.network-page {
    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__network-page__button {
            display: flex;
            gap: $space-xs;
        }
    }
}
