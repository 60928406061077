@import '../../../styles/variables';

.modal {
    .MuiBackdrop-root {
        background-color: rgb(0, 0, 0, 0.6);
    }

    &__header {
        display: flex;
        align-items: center;
    }
    
    &__title {
        flex-grow: 1;
        font-size: map-get($font-size, 'xl');
        padding-bottom: map-get($spacer, 'md')
    }
    
    &__close-button {
        padding: 0 0 map-get($spacer, 'md') map-get($spacer, 'xs');
        border: none;
        justify-self: flex-end;
        background: transparent;
        cursor: pointer;
    }

    &__content-container {
        padding-bottom: map-get($spacer, 'xl');

        li,
        span {
            font-size: map-get($font-size, 'md');
            line-height: map-get($line-height, 'md');
        }
    }

    &__buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: map-get($spacer, 'md');
    }

    &__modal-shadow {
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    } 
}

.modal:not(.full-screen) {
    & [role='dialog'] {
        min-width: $modal-width;
        padding: map-get($spacer, 'lg');
        border: 1px solid $color-neutral-100;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    }
}
