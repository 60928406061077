@import '../../../styles/variables.scss';

.story_stats__container {
  display: flex;
  flex-direction: column;
  gap: map-get($spacer, 'md');
}

.story_stats {

  &.vertical {
    display: flex;
    flex-direction: column;
    gap: map-get($spacer, 'xxs');
    width: 228px;
    margin-left: map-get($spacer, 'md');

    .story_stats__content {
      display: flex;
      flex-direction: column;
      gap: map-get($spacer, 'md');
      border-radius: 15px;
      padding: map-get($spacer, 'sm');
      background: $color-neutral-100;
    }

    .date_details {
      font-style: italic;
      text-align: right;
      color: $color-neutral-600;
    }
  }

  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: map-get($spacer, 'xs') 0 2px;
  }
}
