@import '../../../styles/variables.scss';

.admin_form {
    color: $blue-black;

    h2 {
        margin-bottom: 30px;
    }

    h4 {
      margin-top: 32px;
    }
           
    p {
          margin-top: 12px;
          margin-bottom: 2px;
          &:last-of-type{
            margin-top:0;
            margin-bottom:24px;
          }
    }

    &__create_partner_button{
      margin-top: 64px;
    }
  }
