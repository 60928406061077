@import '../../../styles/variables.scss';

.account_settings {
  &__page {
    .account_edit__form, .page-header {
      max-width: 460px;
    }
    .page-header {
      margin: 0 auto;
    }
  }
}
