@import '../../../styles/variables.scss';
@import '../../../styles/mixins/_general.scss';

.navigation {
  display: flex;
  position: fixed;
  height: $navbarHeight;
  width: 100%;
  top: 0;
  z-index: $z-index-overtake;
  flex-direction: row;
  background-color: $color-neutral-700;
  @media only screen and (min-width: $page-min-width) {
    justify-content: center;
  }

  &.no-overflow {
    padding-right: 15px;
  }

  &__innerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: $page-max-width;
    padding: 0 50px;
    &--logout {
      padding: 6px 16px;
      background: none;
      color: #fff;
      font-size: 1.4rem;
      line-height: 1.43;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }

  &__innerWrapper--left {
    display: flex;
    flex-direction: row;
    &--logo {
      display: flex;
      align-items: center;
      margin: 0 49px 0 0;
      img {
        width: 100px;
      }
    }
    a {
      text-decoration: none;

      &.active {
        li {
          border-bottom: 5px solid $color-primary-400;
        }
      }

      &:hover:not(.active) {
        li {
          border-bottom: 5px solid $color-primary-100;
          background-color: #{$color-bg-100 + '33'};
          cursor: pointer;
        }
      }

      @include outline-styles(
        $focusBorderRadius: 5px,
        $focusBorderGutter: -1px,
        $focusPosition: relative,
        $borderColor: $color-bg-100
      );
    }
    ul {
      display: flex;
      flex-direction: row;
      gap: 4px;
      li {
        height: 100%;
        width: 80px;
        margin: 0 auto; // for gap between yellow bar and focus box
        p {
          color: $text-100;
          font-size: map-get($font-size, 'md');
          font-weight: 500;
          text-align: center;
          line-height: $navbarHeight;
        }
      }
    }
  }
}
