@import '../../../styles/variables.scss';

.error_page {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(40%);

  &__copy {
    display: flex;
    flex-direction: column;
    margin-right: map-get($spacer, 'xl');

    &--title {
      margin-bottom: map-get($spacer, 'md');
    }

    &--body {
      margin-bottom: map-get($spacer, 'xxl');
    }

    
  }

  &__button_container {
    display: flex;
    gap: map-get($spacer, 'xs');
  }

  svg {
    height: 25em;
    width: 25em;
  }
}
