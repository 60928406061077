.dynamic_filters {
  grid-column-start: start;
  grid-column-end: end;
  .select-component {
    margin-top: 0;
    font-size: 1.4rem;
  }
  &--clear {
    background: transparent;
    cursor: pointer;
  }
  button {
    border: none;
    padding: 12px 0;
    align-self: end;
  }
}
