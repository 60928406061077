@import '../../../../app/styles/variables.scss';

.navbar {
  height: 100%;

  &__root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-stepper__container {
    display: flex;
    align-items: center;
  }

  .navbar-stepper__title {
    margin-right: 10px;
    font-size: map-get($font-size, 'sm');
    font-family: $base-font;
    font-weight: 400;
    cursor: default;
  }
}
