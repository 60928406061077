@import '../../../styles/variables.scss';
@import '../../../styles/mixins/link_styles.scss';

.terms_of_service__modal {
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &-header {
        position: absolute;
        &-logo {
            max-width: 81px;
        }
    }

    &-header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    &-content {
        max-width: 460px;
        font-weight: 400;
    }

    &-title {
        margin-bottom: map-get($spacer, 'sm');
        color: $color-neutral-700;
        font-size: map-get($font-size, 'xxl');
        font-weight: 700;
        line-height: map-get($line-height, 'xl');
    }

    &-body {
        font-size: map-get($font-size, 'md');

        p {
            color: $color-neutral-600;
            line-height: map-get($line-height, 'md');
        }

        ul.terms_of_service__modal-terms {
            margin: map-get($spacer, 'sm') 0 map-get($spacer, 'lg')
                map-get($spacer, 'md');
            padding-left: 0;
            color: $color-neutral-600;
            line-height: map-get($line-height, 'md');
            list-style: disc;

            li:not(:last-child) {
                margin-bottom: map-get($spacer, 'xs');
            }
        }
    }

    &-checkbox {
        margin: map-get($spacer, 'md') 0px map-get($spacer, 'lg') 2px;
        color: $color-neutral-600;

        .cr-checkbox__wrapper_box {
            margin-bottom: 11px;     
        }

        .cr-checkbox__label-wrapper {
            #terms-of-service-modal_agree-required {
                visibility: hidden;
            }

            #terms-of-service-modal_agree-error {
                color: $color-error-700;
            }
        }
  
    }
}

