@import '../../../../styles/variables';

.story_header_preview {
  &__wrapper {
    padding: map-get($spacer, sm) map-get($spacer, md);
    background-color: $color-neutral-100;
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__name {
    color: $color-neutral-600;
    margin-left: map-get($spacer, xs);
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba($color-neutral-700, 0.15);
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: map-get($spacer, xs);
    margin-left: map-get($spacer, md);
  }
  &__icon {
    display: flex;
    align-items: center;
  }
}
