@import '../../../../app/styles/variables.scss';

.header {
  &__root {
    min-height: $navbarHeight;
    width: 100%;
    background: $color-bg-100;
    position: sticky;
    left: 0;
    top: 0;
    z-index: $z-index-top;
    border-bottom: 1px solid $color-neutral-200;
  }

  &__content {
    width: 100%;
    max-width: $page-max-width;
    padding: 0 $page-w-padding;
    margin: 0 auto;
    height: $navbarHeight;
  }
}
