@import '../../../../styles/variables.scss';
$z-index-sortable-story-card: $z-index-middle;

.detailed_story {
  &__list_item {
    &--menu_open {
      z-index: $z-index-bottom;
    }
  }
  &--static {
    &_wrapper {
      position: absolute;
      display: inline-flex;
      align-items: center;
      right: 10px;
      top: 15px;
      z-index: $z-index-sortable-story-card;
    }

    &__btn {
      &--drag,
      &--delete {
        background-color: $color-bg-100;
        border: none;
        border-radius: 50%;
        margin: 0 5px;
        padding: 5px;
        z-index: $z-index-top;
      }
      &--drag {
        cursor: grab;
        &:active {
          outline: 2px solid $color-primary-400;
        }
      }
    }
  }
}
