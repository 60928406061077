@import '../../../../styles/variables.scss';

.account_edit {
  &__form {
    margin: map-get($spacer, xl) auto;

  }
  &__inputs {
    border: 0;
    padding: 0;
    margin: 0;

    .input_condition {
      margin-top: map-get($spacer, xxs);
      color: $color-neutral-600;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: map-get($spacer, sm);
    margin-top: map-get($spacer, xl);
  }
  
  &__appearance, &__web {
    display: flex;
    flex-direction: column;
    gap: map-get($spacer, lg);
    max-width: 460px;
    margin-top: map-get($spacer, xl);
    
    &--avatar_title,
    &--avatar_body,
    &--avatar_description {
      color: $color-neutral-600;
    }
    
    &--avatar_title,
    &--avatar_description {
      margin-bottom: map-get($spacer, xxs);
    }

    &--avatar_body {
      list-style-type: disc;
      margin-left:  map-get($spacer, sm);
      margin-bottom:  map-get($spacer, md);
    }
  }
}
