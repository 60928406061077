.galleryListPage {
    .logoWrap {
        margin: auto;
        margin-top: 200px;
    }

    .page__content {
        .gallery_list {
            &--actions + .empty_state {
                /* centering the component according to figma
                    for the story search empty state only in the
                    main gallery list page */
                margin-top: 174px;
            }
        }
    }
}