@import '../../../styles/variables.scss';

.stories_search_filters {
  z-index: $z-index-top;

  display: flex;
  gap: $space-xs;

  .cr-tooltip__buttonWrapper {
    display: inline-block;
    & > .cr__button {
      display: flex;
      align-items: baseline;
      padding: 7px 13px;
      transition: none;
      border: 1px solid $grey;
      border-radius: 4px;
      .cr__icon {
        color: $black;
      }
      // when dropdown is open, set active background color
      &[aria-describedby*='mui'] {
        background-color: $light-grey;
      }
      &:hover {
        background-color: $light-grey;
        color: $black;
      }
      &:focus {
        outline: 1px -webkit-focus-ring-color solid;
      }
      p {
        margin: 0;
      }
    }
    &:hover {
      & > .cr__button {
        border: 1px solid $grey;
        border-radius: 4px;
        color: black;
        .cr__icon {
          color: $black;
        }
      }
    }
  }
  .cr-tooltip {
    pointer-events: auto;
    transition: none !important;
    &__tooltip {
      font-family: inherit;
      width: 668px;
      max-width: none;
      transition: none !important;
      border: 1px solid $grey;
      border-radius: 8px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }
}
