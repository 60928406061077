@import '../../../styles/variables.scss';
.network-gallery {
    position: relative;
    left: 50%;
    width: 100vw;
    margin-top: $space-3xlg;
    margin-left: -50vw;
    padding: $space-6xlg 0 $space-5xlg;
    background: $background-grey;

    .amptravel-gallery--button {
        background-color: $white;
        cursor: pointer;
    }

    &__title {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        h2 {
            margin-right: $space-md;
            font-weight: bold;
        }

        p {
            margin: 0;
        }
    }
}