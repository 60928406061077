@import '../../../../styles/variables';
@import '../../detailed_story/detailed_story_sortable/detailed_story_sortable.scss';

.static_gallery_section_action_bar {
  display: unset;
  justify-content: space-between;
  align-items: center;
  // We need to make sure that content from this action bar can sit on top of the sortable
  // story content if there is ever any overlap, like from tooltips.
  z-index: $z-index-sortable-story-card + 1;

  &__title {
    font-size: map-get($font-size, 'xxl');
    font-weight: 700;
  }
  &__description {
    display: flex;
    align-items: center;
    margin: 10px 20px 0 0;
    color: $color-neutral-600;
    font-size: map-get($font-size, 'md');

    & > p {
      margin: 0;
      color: $color-neutral-600;
      font-size: map-get($font-size, 'md');
    }
  }
  &__info {
    width: 50%;
    display: inline-block;
  }
  &__actions {
    width: 50%;
    display: inline-flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    position: sticky;
    z-index: $z-index-sortable-story-card + 1;
    top: 5px;
    align-items: center;
  }

  &__action-button {
    align-self: flex-start;
    margin-top: 0;
    font-size: map-get($font-size, 'md');
    font-weight: 500;
  }

  & .tooltip__container {
    margin-left: map-get($spacer, 'md')
  }
}
