@import '../../../../styles/variables.scss';

.use_story {
  margin-bottom: map-get($spacer, 'xl');

  &__container {
    display: grid;
    gap: map-get($spacer, 'md');
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    margin-top: map-get($spacer, 'md');
  }
  &__edit-section {
    display: flex;
    align-items: center;
    gap: map-get($spacer, 'lg');
    background-color: $color-primary-0;
    border: 1px solid $color-primary-400;
    border-radius: 10px;
    margin-top: map-get($spacer, 'md');
    padding: map-get($spacer, 'xs') map-get($spacer, 'md');
  }
  &__edit {
    &-btn,
    &-link {
      flex-shrink: 0;
    }
    &-link.link {
      text-decoration: none;
    }
  }
}
