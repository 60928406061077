@import '../../../styles/variables.scss';

.static-gallery-edit-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__header__content {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }

  &__select-stories-container {
    width: 100%;
    max-width: $page-max-width;
    margin: 0 auto;
    padding: map-get($spacer, 'xxl') $page-w-padding map-get($spacer, 'jumbo')
      $page-w-padding;
  }

  &__name {
    width: 460px;
    max-width: 100%;
    margin-bottom: map-get($spacer, 'xxl');
  }
  &__tooltip {
    margin-right: map-get($spacer, 'lg');
  }
  &__empty_state {
    width: 100%;
    background-color: $color-neutral-100;
    margin-top: map-get($spacer, 'xl');
    height: 460px;
    position: static;
    transform: none;
  }
  .gallery_embed {
    width: 60%;
  }
}
