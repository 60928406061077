@import '../../../styles/variables.scss';

$square-button-w: 32px;
$square-button-w-padding: 16px;

@mixin gallery-list-grid-container {
    display: grid;
    grid-template-columns: [start] 25% repeat(auto-fill, 15%) [end];
    grid-template-rows: auto;
}

.gallery_list_grid {
    @include gallery-list-grid-container;
    margin-top: 36px;
    > h5 {
        margin: 0 0 8px 0;
        padding: 0 16px;
        color: $grey;
    }
    .gallery_list_grid_card {
        grid-column-start: start;
        grid-column-end: end;
        @include gallery-list-grid-container;
    }
}