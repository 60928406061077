@use "sass:math";
@import '../../../styles/variables.scss';

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: map-get($spacer, "xxs");
  font-size: map-get($font-size, 'md');

  &__label {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 20px) scale(1);
    line-height: map-get($line-height, 'md');
    color: $color-neutral-500;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &--has-icon {
      transform: translate(25px, 22px) scale(1);
    }

    &--is-animated {
      color: $color-neutral-600;
      transform: translate(0) scale(0.867);
      line-height: map-get($line-height, 'sm');
      transform-origin: top left;
    }

    &--required {
      &:after {
        content: '*';
      }
    }
  }

  &__input_container {
    cursor: pointer;
    margin-top: 24px;
    display: flex;
    padding-bottom: map-get($spacer, "xxs");
    border-bottom: 1px solid $color-neutral-300;
    gap: map-get($spacer, "xxs");

    &:hover {
      border-bottom: 1px solid $color-neutral-700;
    }
  }

  &__input-element {
    padding: 0;
    border: none;
    background: transparent;

    &::placeholder {
      visibility: hidden;
    }
    &::-moz-placeholder {
      opacity: 0;
    }

    &:focus {
      outline: none;

      &::placeholder {
        visibility: visible;
        color: $color-neutral-400;
      }

      &::-moz-placeholder {
        opacity: 1;
      }
  
    }
  }

  &__help-text {
    color: $color-neutral-600;
  }

  &__error-message {
    color: $color-error-700;
  }

  &--is-focused {
    .input__input_container {
      border-bottom: 1px solid $color-neutral-700;
    }
  }

  &--invalid {
    .input__label {
      color: $color-error-700;
    }

    .input__input_container {
      border-bottom: 1px solid $color-error-700;
    }
  }
}
