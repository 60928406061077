@import '../../../styles/variables.scss';
.gallery_embed {
  margin-top: map-get($spacer, 'xxl');

  &__code-area {
    width: 100%;
    margin-top: 30px;
    padding: 0px 16px;
    border: none;
    border-radius: 8px;
    background: $medium-light-grey;
    font-family: 'Courier New', Courier, monospace;
    resize: none;
  }

  &__buttons {
    display: flex;
    gap: $space-xs;
    margin-top: 30px;
    span {
      text-transform: capitalize;
    }
  }

  &__title {
    margin: 0;
    font-size: 17px;
    font-weight: 700;
    line-height: 26px;
  }

  &__description {
    margin: 10px 0 0 0;
    font-size: 14px;
    line-height: 20px;
  }
}
