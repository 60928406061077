@import '../variables.scss';
@import '../functions.scss';
@import './general.scss';

@mixin legacy-button(
  $bgColor: transparent,
  $borderRadius: 30px,
  $color: $text-900,
  $hoverBgColor: null,
  $borderColor: transparent,
  $hoverColor: null,
  $focusBorderWidth: 2px,
  $focusBorderGutter: -4px,
  $focusBorderRadius: 50px,
  $padding: 0,
  $fontSize: map-get($font-size, 'md'),
  $lineHeight: 16px,
  $gapSize: null
) {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gapSize;

  background-color: $bgColor;
  color: $color;
  font-weight: 400;
  font-size: $fontSize;
  padding: $padding;
  line-height: $lineHeight;
  border-radius: $borderRadius;
  @if $borderColor != 0 {
    border: 1px solid $borderColor;
  } @else {
    border: 0;
  }

  &:hover,
  &:active {
    &:not(:disabled) {
      background-color: $hoverBgColor;
      color: $hoverColor;
      text-decoration: none;
    }
  }
  @include outline-styles(
    $focusBorderWidth: $focusBorderWidth,
    $focusBorderRadius: $focusBorderRadius,
    $focusBorderGutter: $focusBorderGutter
  );

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: opacity-equivalent($color, #ffffff, 0.4);
    @if $bgColor != transparent {
      background-color: opacity-equivalent($bgColor, #ffffff, 0.4);
    }
    @if $borderColor != transparent {
      border-color: opacity-equivalent($borderColor, #ffffff, 0.4);
    }
  }
}
