@import '../../../styles/variables.scss';

.story_details {
  color: $blue-black;

  h2 {
    margin-bottom: 30px;
  }

  [class*='__row'] {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 25px;
  }

  [class*='__label'] {
    width: 93px;
    margin: 0;
  }

  [class*='__value'] {
    max-width: 377px - 93px;
    padding-left: 3px;
    font-weight: 700;
    text-transform: capitalize;
  }

  &__row {
    &--status,
    &--creator {
      align-items: center;
      .story-status {
        color: black;
        font-weight: bold;
      }
    }
    &--created_at,
    &--last_updated {
      height: 20px;
    }
  }

  &__label {
    &--expiry {
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: space-between;
      align-self: center;
    }
  }

  &__value--location--label {
    display: block;
  }
  &__visibility {
    display: flex;
    width: 315px;
    align-items: center;
    height: 20px;

    &--visibility_text {
      height: 20px;
      padding-left: 4px;
      font-size: 14px;
      font-weight: bold;
      align-self: center;
    }
  }

  .story_details__row__value {
    &.result {
      color: $color-neutral-600;
      margin: 0;
    }
  }

  &__heading {
    font-weight: 700;
    font-size: map-get($font-size, 'lg');
    color: $color-neutral-700;
    margin: 0;
  }

  &__tooltip_content {
    font-size: map-get($font-size, 'sm');
    line-height: 18px;
    margin: 0;
    margin-top: 20px;
    color: $color-neutral-600;
  }

  &__tooltip__list_container {
    line-height: 2;
    font-size: map-get($font-size, 'sm');
    padding-left: 15px;
    color: $color-neutral-600;

    li {
      list-style: disc;
      padding-top: 10px;
      line-height: 1.43;
      font-weight: normal;
    }
  }
}

.tooltip {
  .tooltip_icon {
    color: $text-900;
    margin: 2px 13px 3px 6px;
  }

  .tooltip_icon:hover {
    color: $color-primary-600;
  }
}
