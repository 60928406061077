@import '../../../styles/variables.scss';

.empty_state {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 100%;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 44rem;
    max-width: 100%;

    .empty_state__desc {
      color: $color-neutral-600;
      max-width: 100%;
      margin-top: map-get($spacer, 'sm');
      text-align: center;
    }
  }
  &__graphic {
    margin-bottom: map-get($spacer, 'lg');
    max-width: 100%;
  }
  &__cta, &__action_content {
    margin-top: map-get($spacer, 'xl');
  }
}
