@import '../../../../styles/variables';
@import '../../../../styles/mixins/general.scss';

.select-stories-section {
  max-width: $page-max-width;
  margin: 0 auto;
  width: 100%;

  &__stories-count {
    display: inline-block;
    line-height: 1;
    margin: map-get($spacer, 'sm') map-get($spacer, 'lg') map-get($spacer, 'sm') 0;
    color: $color-neutral-700;
  }

  .select_story_tooltip{
    position: relative;
    display: inline-flex;
    border: 1px solid $color-neutral-200;
    border-radius: 29px;
    padding: 5px 10px 5px 7px;

    @include outline-styles($focusBorderRadius: 55px);

    &:hover {
      border: 1px solid $color-neutral-600;
    }

    &--description {
      margin: 2px 0 0 5px;
      font-size: map-get($font-size, 'xs');
      align-self: center;
    }

    &--content {
      font-size: map-get($font-size, 'sm');
      line-height: 18px;
      margin: 0;
      color: $color-neutral-600;
    }
  }
  
}
