@import '../../../styles/mixins/link_styles.scss';
@import '../../../styles/variables.scss';

.admin-page {
    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
