@import '../../../styles/variables.scss';
@import '../story/story.scss';
@import '../../../styles/mixins/general.scss';

.detailed_story {
  max-width: 220px;
  border-radius: $card-border-radius;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  .story,
  .story--overlay,
  .story--content {
    border-radius: $card-border-radius $card-border-radius 0 0;
  }
  &--static {
    &_wrapper {
      position: absolute;
      display: inline-flex;
      align-items: center;
      right: 10px;
      top: 15px;
      z-index: $z-index-bottom;
    }
    &_btn {
      background-color: $color-bg-100;
      margin: 0 5px;
      padding: 7px;
    }
  }
  &--content {
    padding: 10px $space-md;
    background-color: $color-bg-100;
    border-radius: 0 0 $card-border-radius $card-border-radius;
    &--publisher {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .publisher-label {
        max-width: calc(100% - 40px);
      }
    }

    &--stats {
      display: flex;
      justify-content: space-between;
      margin-top: $space-md;

      .statistic {
        display: flex;
        align-items: center;

        p {
          margin: 0 0 0 5px;
        }
      }
    }
  }

  &__loading_story_state {
    filter: opacity(25%);
  }

  &__story_component {
    @include outline-styles(
      $focusBorderWidth: 2px,
      $focusBorderRadius: $outline-radius $outline-radius 0 0
    );
  }

  &.disable_story_click {
    pointer-events: none;
  }

  .spinnerLoader.blue {
    position: absolute;
    top: 38%;
    left: 39%;
    height: 40px;
    width: 40px;
    text-align: center;
    z-index: $z-index-middle;
  }
}
