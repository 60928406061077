@import "../../../../styles/variables";
@import '../../../../styles/mixins/general.scss';

.static_gallery_additional_info {
  &__warning {
    display: flex;
    align-items: center;
    margin-right: map-get($spacer, 'lg');
    &__icon {
      margin-right: 5px;
    }
    &__text {
      font-size: map-get($font-size, 'xs');
    }
  }
}

.gallery_preview_tooltip {
  display: flex;
  align-self: center;

  &--icon {
    fill: $color-info-500;
  }

  &--description {
    margin: 2px 0 0 5px;
    font-size: map-get($font-size, 'xs');
    align-self: center;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 29px;
    padding: 7px map-get($spacer, 'xs');
    border: 1px solid $color-neutral-200;
    margin-right: map-get($spacer, 'lg');

    &:hover {
      border: 1px solid $color-neutral-600;
    }

    @include outline-styles($focusBorderRadius: 55px);
  }
}
