@mixin avatarSize($size, $padding-size) {
    width: $size;
    height: $size;

    img {
        width: $size;
        height: $size;
        padding: $padding-size;
    }
}
