@import '../../../styles/variables.scss';
@import '../../../styles/mixins/form_footer.scss';
@import '../../../styles/mixins/legacy_buttons.scss';

.story_manage_page {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--menu_wrapper {
    display: flex;
    gap: map-get($spacer, 'xs');
  }

  .tooltip__container--tooltip_open .story_stats__tooltip_icon {
    opacity: 0.25;
  }
}

.story_manage_page__body {
  display: flex;
  gap: map-get($spacer, 'jumbo') + map-get($spacer, 'xl');
  justify-content: space-between;
  margin-top: map-get($spacer, 'xl');
  padding-bottom: 96px;

  h2 {
    font-weight: 700;
  }

  a {
    display: flex;
    color: $logo-blue-3;

    :first-child {
      margin-right: 3px;
    }
  }

  .story_preview {
    min-width: 285px;
  }

  &__col {
    display: flex;
    flex-direction: column;

    &--story_col {
      display: flex;
    }

    &__story_preview {
      display: flex;
      flex-direction: column;
      align-items: left;
    }
  }

  &__footer {
    @include formFooter();
  }
}

.delete_story_modal {
  &--title {
    font-weight: 700;
  }

  &--description {
    margin: map-get($spacer, 'md') 0;
    font-size: map-get($font-size, 'md');
  }

  &--cta_container {
    display: flex;
    justify-content: flex-end;
    gap: map-get($spacer, 'md');
  }

  // TODO: update after button implementation matches design system (SNET-1595)
  &--accept_btn {
    @include legacy-button(
      $bgColor: $color-error-700,
      $hoverBgColor: $color-error-900,
      $padding: 17px 40px,
      $color: $text-100
    );
  }
}
