@import '../../../styles/variables.scss';
.story_list {
  .story_list__virtualized {
    &,
    & > div {
      overflow: visible !important;
    }
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: map-get($spacer, 'xl');
    margin-bottom: map-get($spacer, 'xl');
    position: sticky;
    top: 5px;
    z-index: $z-index-middle;

    .stories_search_filters {
      margin-left: $space-md;
    }
  }

  &--buttons {
    display: flex;
    gap: map-get($spacer, 'md');

    &__create_story.link {
      text-decoration: none;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    flex-wrap: wrap;
    gap: $space-lg;

    &_wrapper {
      margin-top: map-get($spacer, 'xl');
    }
    // Overridding the height of the list container
    // to reset the height: 0px value.
    // If the height is not reset, then the stick elements
    // will create issue while scrolling till the end of list
    > div {
      height: 100% !important;
    }
  }

  .detailed_story {
    &__story_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #eee;
    }
  }

  &__row {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    gap: map-get($spacer, 'md');

    .story_list__story_wrapper {
      width: 220px;
    }
  }

  &.empty_state {
    /* to centralize emptyState component */
    height: 100%;
  }

  &--search_actions {
    display: flex;
    align-items: center;
  }

  .story_list__footer {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-inline: auto;
  }

  /* enables text to show in two lines while
    maintaining image with correct size */
  &--selectable_empty_state {
    .empty_state__content {
      width: 54rem;

      .empty_state__graphic {
        width: 44rem;
      }
    }
  }
}
