@import '../../../styles/variables.scss';
@import '../../blocks/full_screen_modal/navbar.scss';
@import '../../blocks/full_screen_modal/header.scss';

.static_gallery {
  max-width: 100%;

  &__content {
    width: 100%;
    max-width: $page-max-width;
    height: calc(100% - #{$navbarHeight});
    margin: 0 auto;
    padding: map-get($spacer, 'xxl') $page-w-padding map-get($spacer, 'jumbo')
      $page-w-padding;

    /* empty story list within create gallery
      is not centralized, it just has top margin */
    .story_list > .empty_state {
      height: unset; // to remove height: 100% added in other class
      margin-top: 8rem;
    }
  }
}

.create-gallery-menu-container {
  .menu_list_wrapper {
    position: relative;
    top: map-get($spacer, 'xs');

    .create-gallery-menu-container__support-link {
      font-weight: 500;
      color: $color-info-700;
      text-underline-offset: 0;
      text-decoration: underline;
  
      &:hover:not(:disabled) {
        text-decoration: underline;
      }
  
      svg {
        stroke: $color-info-700;
      }
    }
  }
}
