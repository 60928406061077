@import '../../../styles/variables.scss';
@mixin status($background-color, $dot-color, $text-color) {
    background-color: $background-color;
    color: $text-color;
    svg {
        fill: $dot-color;
        border-radius: 50%;
        background-color: $dot-color;
    }
}

.story-status {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 100px;
    color: $grey;

    svg {
        width: 8px;
        height: 8px;
        margin-right: 4px;
    }

    &.published {
        @include status($color-info-100, $color-info-700, $color-neutral-600)
    }
    &.draft {
        @include status($light-yellow, $deep-yellow, $color-neutral-600)
    }
    &.hidden {
        @include status($background-grey, $color-neutral-600, 'transparent');
        svg {
            background-color: transparent;
            width: 14px;
            height: 14px;
        }
    }
}
