@import '../../../styles/variables.scss';
@import '../../../styles/mixins/general.scss';

.link {
    margin: 0;
    border: none;
    cursor: pointer;

    &-primary {
        color: $color-info-700;
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: 2px;
        &:hover {
            color: $color-info-900;
            text-underline-offset: 2px;
        }
        &:focus-visible {
            outline: none;
          }
    }

    &-icon {
        padding: map-get($spacer, 'xxs');
        border: 1px solid transparent;
        border-radius: 100px;
    
        &:hover {
          background: $color-neutral-100;
        }
    }

    @include outline-styles($focusBorderRadius: 50px, $focusPosition: relative);         
}
