@import '../../../../styles/variables.scss';

.edit_stories_section {
  &--tooltip {
    margin-right: 40px;
  }
  &__empty_state {
    background-color: $color-neutral-100;
    margin-top: 4.5rem;
    min-height: 46rem;
  }
}
