@import '../variables.scss';
@mixin linkButton() {
  padding: 9px 16px;
  border: 1px solid $color-primary-400;
  border-radius: 4px;
  background-color: $color-primary-400;
  color: $black;
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: $color-primary-600;
    background-color: $color-primary-600;
    text-decoration: none;
  }
}
