@import '../../../styles/variables.scss';


.file_upload {
    display: flex;
    align-items: center;
    
    .hidden {
        display: none;
    }
    
    &__cta {
        margin-right: map-get($spacer, 'md')
    }
    
    &__file_info {
        display: flex;
        align-items: center;

        &--icon svg {
            display: block;
        }
        
        &--name {
            margin-left: map-get($spacer, 'xxs')
        }
    }
}
