@import '../../../styles/variables.scss';
@import '../../../styles/mixins/avatar_size.scss';
.avatar-component {
    img {
        border: 1px solid $color-primary-600;
        border-radius: 50%;
    }

    &.small {
        @include avatarSize(25px, 1px);
    }
    &.medium {
        @include avatarSize(45px, 2px);
    }
    &.large {
        @include avatarSize(65px, 2px);
    }
}
