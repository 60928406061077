@mixin storyDimensions($width: 220px, $height: auto, $min-height: auto) {
    max-width: $width;
    height: $height;
    img {
        min-height: $min-height;
        max-height: $height;
    }
    &--overlay {
        min-height: $min-height;
        p {
            max-width: $width;
        }
    }
}
