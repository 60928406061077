@import '../../../styles/variables.scss';

.search {
    display: inline-flex;
    position: relative;

    .cr__icon-search {
        position: absolute;
        top: 18px;
        left: 15px;
    }

    &__input {
        width: 460px;
        height: 48px;
        padding: 18px 38px;
        border-radius: 100px;
        border: 1px solid transparent;
        background: $color-neutral-100;

        &::placeholder {
            color: $color-neutral-600;
            font-size: map-get($font-size, 'md');
        }

        &:focus {
            &::placeholder {
                color: $color-neutral-300;
            }
            border: 1px solid $color-neutral-700;
            // remove default outline
            outline: none;
        }
        &[type='search']::-webkit-search-cancel-button {
            // This removes the clear button that is only visible in chrome
            /* stylelint-disable property-no-vendor-prefix */
            -webkit-appearance: none;
        }
    }

    &__btn-clear {
        position: absolute;
        right: 15px;
        top: 17px;
        background: none;
        border: none;
        padding: 0;
        margin: 0;

        cursor: pointer;
    }

    &__form {
        margin: 0;
    }
}
