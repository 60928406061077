@import '../../../styles/variables';

.btn {
  // TODO: Align with design on replacing this value with a theme variable
  gap: 8px;
  // need to override these styles because
  // flock adds a height and width of 30px on the loading spinner
  // which causes the button height to grow in loading state
  // leading to bad UX
  line-height: map-get($line-height, 'md');
  & .spinner {
    width: map-get($spacer, 'sm');
    height: map-get($spacer, 'sm');
  }
  & .spinner__bars {
    background: $text-100;
    height: 25%;
  }
}
