@import '../../../styles/variables.scss';
@import '../../../styles/mixins/text.scss';

.text {
  &-h1 {
    @include textStyle(
      $fontSize: map-get($font-size, 'jumbo'),
      $fontWeight: 700,
      $lineHeight: map-get($line-height, 'jumbo')
    );
  }
  &-h2 {
    @include textStyle(
      $fontSize: map-get($font-size, 'xxl'),
      $fontWeight: 700,
      $lineHeight: map-get($line-height, 'xl')
    );
  }
  &-h3 {
    @include textStyle(
      $fontSize: map-get($font-size, 'xl'),
      $fontWeight: 700,
      $lineHeight: map-get($line-height, 'xl')
    );
  }
  &-h4 {
    @include textStyle(
      $fontSize: map-get($font-size, 'lg'),
      $fontWeight: 700,
      $lineHeight: map-get($line-height, 'lg')
    );
  }
  &-b1 {
    @include textStyle(
      $fontSize: map-get($font-size, 'md'),
      $fontWeight: 400,
      $lineHeight: map-get($line-height, 'md')
    );
  }
  &-b12 {
    @include textStyle(
      $fontSize: map-get($font-size, 'md'),
      $fontWeight: 700,
      $lineHeight: map-get($line-height, 'md')
    );
  }
  &-b2 {
    @include textStyle(
      $fontSize: map-get($font-size, 'sm'),
      $fontWeight: 400,
      $lineHeight: map-get($line-height, 'sm')
    );
  }
  &-b22 {
    @include textStyle(
      $fontSize: map-get($font-size, 'sm'),
      $fontWeight: 700,
      $lineHeight: map-get($line-height, 'sm')
    );
  }
  &-b23 {
    @include textStyle(
      $fontSize: map-get($font-size, 'sm'),
      $fontWeight: 400,
      $lineHeight: map-get($line-height, 'sm'),
      $fontStyle: italic
    );
  }
  &-c1 {
    @include textStyle(
      $fontSize: map-get($font-size, 'xs'),
      $fontWeight: 400,
      $lineHeight: map-get($line-height, 'xs')
    );
  }
  &-c12 {
    @include textStyle(
      $fontSize: map-get($font-size, 'xs'),
      $fontWeight: 500,
      $lineHeight: map-get($line-height, 'xs')
    );
  }
}
